<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-button @click="$router.push('/jewelry')" class="md-primary"
        >back</md-button
      >
      <md-card>
        <md-card-content class="t-container">
          <div class="t-label">
            <label>Select Template </label>
            <div class="info-icon" @click="showInfo = !showInfo">?</div>
          </div>

          <v-select
            class="t-select"
            required
            appendToBody
            v-model="selectedTemplate"
            :options="templates"
            label="name"
            :reduce="(t) => t.id"
          >
            <template v-slot:option="option">
              {{ `Template ${option.id}: ${option.name}` }}
            </template>
          </v-select>
        </md-card-content>
        <md-card-content>
          <div v-if="selectedTemplate">
            <div class="field-inputs">
              <!-- General Information -->
              <md-card>
                <md-card-header>
                  <md-card-header-text>
                    <div class="md-title">General Jewelry Information</div>
                  </md-card-header-text>
                </md-card-header>
                <md-card-content class="field-inputs">
                  <div class="md-field noFlex">
                    <div class="form">
                      <div class="static"><p>Client</p></div>
                      <v-select
                        required
                        label="name"
                        appendToBody
                        :reduce="(c) => c.id"
                        :options="companies"
                        v-model="selectedCompany"
                      >
                      </v-select>
                    </div>
                    <span class="md-error">Missing Field</span>
                  </div>
                  <md-field>
                    <label>Customer</label>
                    <md-input
                      disabled
                      type="text"
                      :value="jewelry.Customer"
                    ></md-input>
                  </md-field>
                  <md-field ref="ClientSKU">
                    <label>SKU</label>
                    <md-input
                      @change="handleCertSKUInfo($event, 'ClientSKU')"
                      @input="jewelry.Description = jewelry.ClientSKU"
                      type="text"
                      v-model="jewelry.ClientSKU"
                    ></md-input>

                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field ref="CertNum">
                    <label>DIS Certificate #</label>
                    <md-input
                      type="text"
                      @change="handleCertSKUInfo($event, 'CertNum')"
                      v-model="jewelry.CertNum"
                    ></md-input>
                    <div class="checkbox-wrapper">
                      <span>Report Stolen</span>
                      <md-checkbox v-model="jewelry.isStolen"></md-checkbox>
                    </div>
                  </md-field>
                  <md-field
                    :class="{ 'md-invalid': errors['JewelryType'] }"
                    ref="JewelryType"
                    class="noFlex"
                  >
                    <div class="form">
                      <div class="static"><p>Jewelry Type</p></div>
                      <v-select
                        appendToBody
                        v-model="jewelry.JewelryType"
                        required
                        :options="jewelry_type"
                      >
                      </v-select>
                    </div>
                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field
                    class="noFlex"
                    :class="{ 'md-invalid': errors['MainStoneColorHue'] }"
                  >
                    <div class="form">
                      <div class="static">
                        <p>Metal</p>
                      </div>

                      <v-select
                        appendToBody
                        :options="metals"
                        v-model="jewelry.Metal"
                      ></v-select>
                    </div>
                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field>
                    <label>Inscription(s)</label>
                    <md-input
                      type="text"
                      v-model="jewelry.Inscription"
                    ></md-input>
                  </md-field>

                  <md-field>
                    <label>Video File Name</label>
                    <md-input v-model="jewelry.Video.name"> </md-input>
                  </md-field>
                </md-card-content>
              </md-card>

              <!-- Main Stone Card -->
              <!-- <md-switch
                v-if="selectedTemplate == 4 || selectedTemplate == 13"
                @change="handleMultStones($event, Boolean(jewelry.created))"
                v-model="jewelry.IsMultipleCenterStones"
                >Multiple Center Stones</md-switch
              > -->
              <div
                v-if="
                  jewelry.IsMultipleCenterStones &&
                  (selectedTemplate == 4 || selectedTemplate == 13)
                "
              >
                <div class="stone-options">
                  <div class="tabs">
                    <div
                      v-for="(item, index) in jewelry.MultipleCenterStones"
                      :key="index"
                      class="tab"
                      :class="{ highlighted: index == selectedTab }"
                      @click="toggleTab(index)"
                    >
                      <!-- <div
                        v-if="index"
                        class="rmvTab"
                        @click="removeStone(index)"
                      >
                        x
                      </div> -->
                      <div>{{ index + 1 }}</div>
                    </div>
                    <!-- <img
                      src="/img/add.png"
                      @click="addCenterStone"
                      style="height: 30px"
                    /> -->
                  </div>
                </div>

                <div class="content_wrapper">
                  <div
                    v-for="(item, index) in jewelry.MultipleCenterStones"
                    :key="index"
                  >
                    <md-card v-if="index == selectedTab" :key="index">
                      <md-card-header>
                        <md-card-header-text>
                          <div class="md-title">
                            Center Stone #{{ index + 1 }} Information
                          </div>
                        </md-card-header-text>
                      </md-card-header>

                      <md-card-content class="field-inputs">
                        <md-field
                          class="noFlex"
                          :class="{ 'md-invalid': errors['MainStoneColorHue'] }"
                        >
                          <div class="static">
                            <p>
                              Main Stone Type <span class="required">*</span>
                            </p>
                          </div>

                          <v-select
                            appendToBody
                            style="margin-top: 50px"
                            :options="stone_types"
                            v-model="item.MainStoneType"
                          ></v-select>

                          <span class="md-error">Missing Field</span>
                        </md-field>

                        <md-field
                          :class="{
                            'md-invalid': errors['MainStoneColor'],
                          }"
                        >
                          <div class="color-select">
                            <div class="static"><p>Asterisks</p></div>
                            <div class="options">
                              <div
                                v-for="(asterisk, a_index) in asterisks"
                                :key="a_index"
                              >
                                <input
                                  type="radio"
                                  @change="forceUpdateItem"
                                  :id="a_index"
                                  :value="asterisk.value"
                                  v-model="item.MainStoneColor.asterisks"
                                />{{ asterisk.name }}
                              </div>
                            </div>
                            <div class="static">
                              <p>
                                Main Stone Color <span class="required">*</span>
                              </p>
                            </div>
                            <div class="options">
                              <div
                                v-for="(color, c_index) in Object.keys(colors)"
                                :key="c_index"
                              >
                                <input
                                  type="radio"
                                  :id="index"
                                  :value="color"
                                  @change="handleColorChangesForMult(item)"
                                  v-model="item.MainStoneColor.template"
                                />{{ color }}
                              </div>
                            </div>

                            <v-select
                              appendToBody
                              :options="
                                colors[item.MainStoneColor.template].value
                              "
                              v-model="item.MainStoneColor.value"
                            >
                            </v-select>
                            <span class="md-error">Missing Field</span>
                          </div>
                        </md-field>

                        <md-field
                          :class="{
                            'md-invalid': errors['MainStoneClarity'],
                          }"
                          ref="MainStoneClarity"
                        >
                          <div class="color-select">
                            <div class="static"><p>Asterisks</p></div>
                            <div class="options">
                              <div
                                v-for="(asterisk, a_index) in asterisks"
                                :key="a_index"
                              >
                                <input
                                  type="radio"
                                  :id="a_index"
                                  @change="forceUpdateItem"
                                  :value="asterisk.value"
                                  v-model="item.MainStoneClarity.asterisks"
                                />{{ asterisk.name }}
                              </div>
                            </div>
                            <div class="static">
                              <p>
                                Main Stone Clarity
                                <span class="required">*</span>
                              </p>
                            </div>
                            <v-select
                              style="margin-top: 50px"
                              appendToBody
                              :options="clarity"
                              v-model="item.MainStoneClarity.value"
                            >
                            </v-select>
                            <span class="md-error">Missing Field</span>
                          </div>
                        </md-field>

                        <md-field
                          v-if="selectedTemplate == 4"
                          :class="{ 'md-invalid': errors['MainStoneCut'] }"
                          ref="MainStoneCut"
                          class="noFlex"
                        >
                          <div class="static"><p>Center Stone Cut</p></div>
                          <v-select
                            appendToBody
                            required
                            :options="cut"
                            v-model="item.MainStoneCut"
                          >
                          </v-select>
                          <span class="md-error">Missing Field</span>
                        </md-field>

                        <md-field
                          :class="{
                            'md-invalid': errors['MainStonePolish'],
                          }"
                          class="noFlex"
                          ref="MainStonePolish"
                        >
                          <div class="static"><p>Center Stone Polish</p></div>
                          <v-select
                            appendToBody
                            required
                            v-model="item.MainStonePolish"
                            :options="polish"
                          >
                          </v-select>
                          <span class="md-error">Missing Field</span>
                        </md-field>

                        <md-field
                          :class="{
                            'md-invalid': errors['MainStoneSymmetry'],
                          }"
                          class="noFlex"
                          ref="MainStoneSymmetry"
                        >
                          <div class="static"><p>Center Stone Symmetry</p></div>
                          <v-select
                            appendToBody
                            required
                            v-model="item.MainStoneSymmetry"
                            :options="symmetry"
                          >
                          </v-select>
                          <span class="md-error">Missing Field</span>
                        </md-field>

                        <md-field
                          :class="{
                            'md-invalid': errors['MainStoneShape'],
                          }"
                          class="noFlex"
                          ref="MainStoneShape"
                        >
                          <div class="static"><p>Center Stone Shape</p></div>

                          <v-select
                            appendToBody
                            required
                            v-model="item.MainStoneShape"
                            :options="shapes"
                          >
                          </v-select>
                          <span class="md-error">Missing Field</span>
                        </md-field>

                        <md-field
                          :class="{
                            'md-invalid': errors['MainStoneTable'],
                          }"
                          ref="MainStoneTable"
                        >
                          <label>Center Stone Table(in %)</label>
                          <md-input
                            required
                            type="number"
                            v-model.number="item.MainStoneTable"
                          ></md-input>
                          <span class="md-error">Missing Field</span>
                        </md-field>

                        <md-field
                          :class="{
                            'md-invalid': errors['MainStoneDepth'],
                          }"
                          ref="MainStoneDepth"
                        >
                          <label>Center Stone Depth(in %)</label>
                          <md-input
                            required
                            type="number"
                            v-model.number="item.MainStoneDepth"
                          ></md-input>
                          <span class="md-error">Missing Field</span>
                        </md-field>

                        <md-field
                          :class="{
                            'md-invalid': errors['MainStoneWeight'],
                          }"
                          ref="MainStoneWeight"
                        >
                          <label>Center Stone Total Weight</label>
                          <md-input
                            required
                            type="number"
                            v-model.number="item.MainStoneWeight"
                          ></md-input>
                          <span class="md-error">Missing Field</span>
                        </md-field>

                        <md-field>
                          <label
                            >Center Stone Measurements (ex: 12.08 x 7.98 x 4.99
                            mm)</label
                          >
                          <md-input
                            @change="
                              handleMeasurementsForMult(
                                $event,
                                item,
                                'MainStoneMeasurements'
                              )
                            "
                            v-model="item.MainStoneMeasurements"
                            type="text"
                          ></md-input>
                        </md-field>
                      </md-card-content>
                    </md-card>
                  </div>
                </div>
              </div>

              <md-card v-else>
                <md-card-header>
                  <md-card-header-text>
                    <div
                      v-if="
                        selectedTemplate == 17 ||
                        selectedTemplate == 15 ||
                        selectedTemplate == 20
                      "
                      class="md-title"
                    >
                      Gem Stone Information
                    </div>

                    <div v-else class="md-title">Main Stone Information</div>
                  </md-card-header-text>
                </md-card-header>

                <md-card-content
                  v-if="
                    selectedTemplate == 17 ||
                    selectedTemplate == 15 ||
                    selectedTemplate == 20 ||
                    selectedTemplate == 22 ||
                    selectedTemplate == 23 ||
                    selectedTemplate == 21
                  "
                  class="field-inputs"
                >
                  <md-field
                    class="noFlex"
                    :class="{ 'md-invalid': errors['MainStoneColorHue'] }"
                  >
                    <div class="static">
                      <p>Main Stone Type <span class="required">*</span></p>
                    </div>

                    <v-select
                      appendToBody
                      style="margin-top: 50px"
                      :options="stone_types"
                      v-model="jewelry.MainStoneType"
                    ></v-select>

                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field
                    v-if="jewelry.MainStoneType.includes('Diamond')"
                    :class="{ 'md-invalid': errors['MainStoneColor'] }"
                  >
                    <div class="color-select">
                      <div class="static"><p>Asterisks</p></div>
                      <div class="options">
                        <div
                          v-for="(asterisk, index) in asterisks"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :value="asterisk.value"
                            v-model="jewelry.MainStoneColor.asterisks"
                          />{{ asterisk.name }}
                        </div>
                      </div>
                      <div class="static">
                        <p>Main Stone Color <span class="required">*</span></p>
                      </div>
                      <div class="options">
                        <div
                          v-for="(color, index) in Object.keys(colors)"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :value="color"
                            @change="
                              handleColorChanges('MainStoneColor', 'value')
                            "
                            v-model="jewelry.MainStoneColor.template"
                          />{{ color }}
                        </div>
                      </div>

                      <v-select
                        appendToBody
                        :options="colors[jewelry.MainStoneColor.template].value"
                        v-model="jewelry.MainStoneColor.value"
                      ></v-select>

                      <span class="md-error">Missing Field</span>
                    </div>
                  </md-field>

                  <md-field
                    :class="{ 'md-invalid': errors['MainStoneShape'] }"
                    ref="MainStoneShape"
                    class="noFlex"
                  >
                    <div class="static">
                      <p>Main Stone Shape <span class="required">*</span></p>
                    </div>
                    <v-select
                      style="margin-top: 50px"
                      appendToBody
                      :options="shapes"
                      :required="selectedTemplate == 1"
                      v-model="jewelry.MainStoneShape"
                    >
                    </v-select>
                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field
                    v-if="jewelry.MainStoneType.includes('Diamond')"
                    :class="{ 'md-invalid': errors['MainStoneClarity'] }"
                    ref="MainStoneClarity"
                  >
                    <div class="color-select">
                      <div class="static"><p>Asterisks</p></div>
                      <div class="options">
                        <div
                          v-for="(asterisk, index) in asterisks"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :value="asterisk.value"
                            v-model="jewelry.MainStoneClarity.asterisks"
                          />{{ asterisk.name }}
                        </div>
                      </div>
                      <div class="static">
                        <p>
                          Main Stone Clarity
                          <span
                            v-if="
                              selectedTemplate !== 22 && selectedTemplate !== 23
                            "
                            class="required"
                            >*</span
                          >
                        </p>
                      </div>
                      <v-select
                        appendToBody
                        :options="clarity"
                        v-model="jewelry.MainStoneClarity.value"
                      ></v-select>
                      <span class="md-error">Missing Field</span>
                    </div>
                  </md-field>

                  <md-field
                    :class="{ 'md-invalid': errors['MainStoneWeight'] }"
                    ref="MainStoneWeight"
                    style="margin-top: 50px"
                  >
                    <label>Main Stone Carat Weight</label>
                    <md-input
                      required
                      type="number"
                      v-model="jewelry.MainStoneWeight"
                    ></md-input>
                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field style="margin-top: 50px">
                    <label
                      >Main Stone Measurements (ex: 12.08 x 7.98 x 4.99
                      mm)</label
                    >
                    <md-input
                      @change="
                        handleMeasurements($event, 'MainStoneMeasurements')
                      "
                      v-model="jewelry.MainStoneMeasurements"
                      type="text"
                    ></md-input>
                  </md-field>

                  <div class="color-code-wrapper">
                    <div class="color-code-label">Color Code</div>
                    <color-picker
                      @input="updateColorValue($event, 'MainStoneColorCode')"
                      v-model="jewelry.MainStoneColorCode"
                    ></color-picker>
                  </div>
                </md-card-content>

                <md-card-content v-else class="field-inputs">
                  <md-field
                    class="noFlex"
                    :class="{ 'md-invalid': errors['MainStoneColorHue'] }"
                  >
                    <div class="static">
                      <p>Main Stone Type <span class="required">*</span></p>
                    </div>

                    <v-select
                      appendToBody
                      style="margin-top: 50px"
                      :options="stone_types"
                      v-model="jewelry.MainStoneType"
                    ></v-select>

                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field></md-field>
                  <!-- Main Stone Color -->
                  <md-field
                    v-if="selectedTemplate != 14"
                    :class="{ 'md-invalid': errors['MainStoneColor'] }"
                  >
                    <div class="color-select">
                      <div class="static"><p>Asterisks</p></div>
                      <div class="options">
                        <div
                          v-for="(asterisk, index) in asterisks"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :value="asterisk.value"
                            v-model="jewelry.MainStoneColor.asterisks"
                          />{{ asterisk.name }}
                        </div>
                      </div>
                      <div class="static">
                        <p>Main Stone Color <span class="required">*</span></p>
                      </div>
                      <div class="options">
                        <div
                          v-for="(color, index) in Object.keys(colors)"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :value="color"
                            @change="
                              handleColorChanges('MainStoneColor', 'value')
                            "
                            v-model="jewelry.MainStoneColor.template"
                          />{{ color }}
                        </div>
                      </div>

                      <v-select
                        appendToBody
                        :options="colors[jewelry.MainStoneColor.template].value"
                        v-model="jewelry.MainStoneColor.value"
                      ></v-select>

                      <span class="md-error">Missing Field</span>
                    </div>
                  </md-field>

                  <!-- Main Stone Color 2 -->
                  <md-field
                    :class="{ 'md-invalid': errors['MainStoneColor2'] }"
                    ref="MainStoneColor2"
                    v-if="
                      selectedTemplate == 1 ||
                      selectedTemplate == 2 ||
                      selectedTemplate == 11 ||
                      selectedTemplate == 21
                    "
                  >
                    <div class="color-select">
                      <div class="static"><p>Asterisks</p></div>
                      <div class="options">
                        <div
                          v-for="(asterisk, index) in asterisks"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :value="asterisk.value"
                            v-model="jewelry.MainStoneColor2.asterisks"
                          />{{ asterisk.name }}
                        </div>
                      </div>
                      <div class="static">
                        <p>Main Stone Color #2</p>
                      </div>
                      <div class="options">
                        <div
                          v-for="(color, index) in Object.keys(colors)"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :value="color"
                            @change="
                              handleColorChanges('MainStoneColor2', 'value')
                            "
                            v-model="jewelry.MainStoneColor2.template"
                          />{{ color }}
                        </div>
                      </div>

                      <v-select
                        appendToBody
                        :options="
                          colors[jewelry.MainStoneColor2.template].value
                        "
                        v-model="jewelry.MainStoneColor2.value"
                      ></v-select>
                      <span class="md-error">Missing Field</span>
                    </div>
                  </md-field>

                  <md-field
                    v-if="selectedTemplate != 14"
                    :class="{ 'md-invalid': errors['MainStoneClarity'] }"
                    ref="MainStoneClarity"
                  >
                    <div class="color-select">
                      <div class="static"><p>Asterisks</p></div>
                      <div class="options">
                        <div
                          v-for="(asterisk, index) in asterisks"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :value="asterisk.value"
                            v-model="jewelry.MainStoneClarity.asterisks"
                          />{{ asterisk.name }}
                        </div>
                      </div>
                      <div class="static">
                        <p>
                          Main Stone Clarity
                          <span
                            v-if="
                              selectedTemplate !== 22 && selectedTemplate !== 23
                            "
                            class="required"
                            >*</span
                          >
                        </p>
                      </div>
                      <v-select
                        appendToBody
                        :options="clarity"
                        v-model="jewelry.MainStoneClarity.value"
                      ></v-select>
                      <span class="md-error">Missing Field</span>
                    </div>
                  </md-field>

                  <md-field
                    :class="{ 'md-invalid': errors['MainStoneClarity2'] }"
                    ref="MainStoneClarity2"
                    v-if="
                      selectedTemplate == 1 ||
                      selectedTemplate == 2 ||
                      selectedTemplate == 10 ||
                      selectedTemplate == 21
                    "
                  >
                    <div class="color-select">
                      <div class="static"><p>Asterisks</p></div>
                      <div class="options">
                        <div
                          v-for="(asterisk, index) in asterisks"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :value="asterisk.value"
                            v-model="jewelry.MainStoneClarity2.asterisks"
                          />{{ asterisk.name }}
                        </div>
                      </div>
                      <div class="static">
                        <p>
                          Main Stone Clarity #2<span class="required">*</span>
                        </p>
                      </div>

                      <v-select
                        appendToBody
                        :options="clarity"
                        v-model="jewelry.MainStoneClarity2.value"
                      ></v-select>
                      <span class="md-error">Missing Field</span>
                    </div>
                  </md-field>

                  <md-field
                    :class="{ 'md-invalid': errors['MainStoneCut'] }"
                    ref="MainStoneCut"
                    class="noFlex"
                    v-if="
                      selectedTemplate == 1 ||
                      selectedTemplate == 8 ||
                      selectedTemplate == 4
                    "
                  >
                    <div class="static"><p>Main Stone Cut</p></div>
                    <v-select
                      appendToBody
                      :options="cut"
                      required
                      v-model="jewelry.MainStoneCut"
                    >
                    </v-select>
                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field
                    :class="{ 'md-invalid': errors['MainStonePolish'] }"
                    ref="MainStonePolish"
                    class="noFlex"
                    v-if="
                      selectedTemplate == 1 ||
                      selectedTemplate == 8 ||
                      selectedTemplate == 9 ||
                      selectedTemplate == 4 ||
                      selectedTemplate == 6
                    "
                  >
                    <div class="static"><p>Main Stone Polish</p></div>
                    <v-select
                      appendToBody
                      :options="polish"
                      required
                      v-model="jewelry.MainStonePolish"
                    >
                    </v-select>
                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field
                    :class="{ 'md-invalid': errors['MainStoneSymmetry'] }"
                    class="noFlex"
                    ref="MainStoneSymmetry"
                    v-if="
                      selectedTemplate == 1 ||
                      selectedTemplate == 8 ||
                      selectedTemplate == 9 ||
                      selectedTemplate == 4 ||
                      selectedTemplate == 6
                    "
                  >
                    <div class="static"><p>Main Stone Symmetry</p></div>
                    <v-select
                      style="margin-top: 50px"
                      appendToBody
                      :options="symmetry"
                      required
                      v-model="jewelry.MainStoneSymmetry"
                    >
                    </v-select>
                    <span class="md-error">Missing Field</span>
                  </md-field>
                  <md-field
                    :class="{ 'md-invalid': errors['MainStoneShape'] }"
                    ref="MainStoneShape"
                    class="noFlex"
                  >
                    <div class="static">
                      <p>Main Stone Shape <span class="required">*</span></p>
                    </div>
                    <v-select
                      style="margin-top: 50px"
                      appendToBody
                      :options="shapes"
                      :required="selectedTemplate == 1"
                      v-model="jewelry.MainStoneShape"
                    >
                    </v-select>
                    <span class="md-error">Missing Field</span>
                  </md-field>
                  <md-field
                    :class="{ 'md-invalid': errors['MainStoneTable'] }"
                    ref="MainStoneTable"
                    v-if="
                      selectedTemplate == 1 ||
                      selectedTemplate == 8 ||
                      selectedTemplate == 9 ||
                      selectedTemplate == 4 ||
                      selectedTemplate == 6
                    "
                  >
                    <label>Main Stone Table(in %)</label>
                    <md-input
                      required
                      type="number"
                      v-model.number="jewelry.MainStoneTable"
                    ></md-input>
                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field
                    :class="{ 'md-invalid': errors['MainStoneDepth'] }"
                    ref="MainStoneDepth"
                    v-if="
                      selectedTemplate == 1 ||
                      selectedTemplate == 8 ||
                      selectedTemplate == 9 ||
                      selectedTemplate == 4 ||
                      selectedTemplate == 6
                    "
                  >
                    <label>Main Stone Depth(in %)</label>
                    <md-input
                      required
                      type="number"
                      v-model.number="jewelry.MainStoneDepth"
                    ></md-input>
                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field
                    :class="{ 'md-invalid': errors['MainStoneWeight'] }"
                    ref="MainStoneWeight"
                    style="margin-top: 50px"
                  >
                    <label>Main Stone Carat Weight</label>
                    <md-input
                      required
                      type="number"
                      v-model="jewelry.MainStoneWeight"
                    ></md-input>
                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field
                    style="margin-top: 50px"
                    v-if="
                      selectedTemplate != 7 &&
                      selectedTemplate != 2 &&
                      selectedTemplate != 12
                    "
                  >
                    <label
                      >Main Stone Measurements (ex: 12.08 x 7.98 x 4.99
                      mm)</label
                    >
                    <md-input
                      @change="
                        handleMeasurements($event, 'MainStoneMeasurements')
                      "
                      v-model="jewelry.MainStoneMeasurements"
                      type="text"
                    ></md-input>
                  </md-field>
                  <div class="color-code-wrapper">
                    <div class="color-code-label">Color Code</div>

                    <color-picker
                      @input="updateColorValue($event, 'MainStoneColorCode')"
                      v-model="jewelry.MainStoneColorCode"
                    ></color-picker>
                  </div>
                </md-card-content>
              </md-card>
              <!-- <md-field>
                <label>Material</label>
                <md-input required type="text" v-model="jewelry.material"></md-input>
                <span class="md-error">Missing Field</span>
              </md-field>-->
              <!-- Center Stone Card -->

              <!-- Side Stone Card -->
              <md-switch
                v-if="
                  selectedTemplate != 2 &&
                  selectedTemplate != 4 &&
                  selectedTemplate != 8 &&
                  selectedTemplate != 9 &&
                  selectedTemplate != 13 &&
                  selectedTemplate != 14 &&
                  selectedTemplate != 15 &&
                  selectedTemplate != 16 &&
                  selectedTemplate != 17 &&
                  selectedTemplate != 19 &&
                  selectedTemplate != 20
                "
                v-model="jewelry.HasSideStones"
                >Add Side Stone</md-switch
              >
              <md-card
                v-if="
                  jewelry.HasSideStones ||
                  selectedTemplate == 16 ||
                  selectedTemplate == 15 ||
                  selectedTemplate == 20
                "
              >
                <md-card-header>
                  <md-card-header-text>
                    <div
                      v-if="selectedTemplate == 16 || selectedTemplate == 20"
                      class="md-title"
                    >
                      Gem Stone Information
                    </div>
                    <div v-else class="md-title">Side Stone Information</div>
                  </md-card-header-text>
                </md-card-header>

                <md-card-content
                  v-if="
                    selectedTemplate == 16 ||
                    selectedTemplate == 17 ||
                    selectedTemplate == 15 ||
                    selectedTemplate == 20 ||
                    selectedTemplate == 22 ||
                    selectedTemplate == 23 ||
                    selectedTemplate == 21
                  "
                  class="field-inputs"
                >
                  <md-field
                    class="noFlex"
                    :class="{ 'md-invalid': errors['SideStoneType'] }"
                  >
                    <div class="static">
                      <p>Side Stone Type <span class="required">*</span></p>
                    </div>

                    <v-select
                      appendToBody
                      style="margin-top: 50px"
                      :options="stone_types"
                      v-model="jewelry.SideStoneType"
                    ></v-select>

                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field
                    v-if="jewelry.SideStoneType.includes('Diamond')"
                    :class="{ 'md-invalid': errors['SideStoneColor'] }"
                  >
                    <div class="color-select">
                      <div class="static"><p>Asterisks</p></div>
                      <div class="options">
                        <div
                          v-for="(asterisk, index) in asterisks"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :value="asterisk.value"
                            v-model="jewelry.SideStoneColor.asterisks"
                          />{{ asterisk.name }}
                        </div>
                      </div>
                      <div class="static">
                        <p>Stone Color <span class="required">*</span></p>
                      </div>
                      <div class="options">
                        <div
                          v-for="(color, index) in Object.keys(colors)"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :value="color"
                            @change="
                              handleColorChanges('SideStoneColor', 'value')
                            "
                            v-model="jewelry.SideStoneColor.template"
                          />{{ color }}
                        </div>
                      </div>

                      <v-select
                        appendToBody
                        :options="colors[jewelry.SideStoneColor.template].value"
                        v-model="jewelry.SideStoneColor.value"
                      ></v-select>

                      <span class="md-error">Missing Field</span>
                    </div>
                  </md-field>

                  <md-field
                    v-if="jewelry.SideStoneType.includes('Diamond')"
                    :class="{ 'md-invalid': errors['SideStoneClarity'] }"
                    ref="SideStoneClarity"
                  >
                    <div class="color-select">
                      <div class="static"><p>Asterisks</p></div>
                      <div class="options">
                        <div
                          v-for="(asterisk, index) in asterisks"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :value="asterisk.value"
                            v-model="jewelry.SideStoneClarity.asterisks"
                          />{{ asterisk.name }}
                        </div>
                      </div>
                      <div class="static">
                        <p>
                          Stone Clarity
                          <span v-if="selectedTemplate != 14" class="required"
                            >*</span
                          >
                        </p>
                      </div>
                      <div style="margin-top: 50px">
                        <v-select
                          appendToBody
                          :options="clarity"
                          v-model="jewelry.SideStoneClarity.value"
                        >
                        </v-select>
                      </div>
                      <span class="md-error">Missing Field</span>
                    </div>
                  </md-field>

                  <md-field
                    :class="{ 'md-invalid': errors['SideStoneShape'] }"
                    ref="SideStoneShape"
                    class="noFlex"
                  >
                    <div class="static">
                      <p>Stone Shape <span class="required">*</span></p>
                    </div>
                    <v-select
                      style="margin-top: 50px"
                      appendToBody
                      :options="shapes"
                      :required="selectedTemplate == 1"
                      v-model="jewelry.SideStoneShape"
                    >
                    </v-select>
                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field
                    :class="{ 'md-invalid': errors['SideStoneWeight'] }"
                    ref="SideStoneWeight"
                  >
                    <label>Stone Carat Weight</label>
                    <md-input
                      style="margin-top: 50px"
                      required
                      type="number"
                      v-model="jewelry.SideStoneWeight"
                    ></md-input>
                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field style="margin-top: 50px">
                    <label>
                      Stone Measurements (ex: 12.08 x 7.98 x 4.99 mm)</label
                    >
                    <md-input
                      @change="
                        handleMeasurements($event, 'SideStoneMeasurements')
                      "
                      v-model="jewelry.SideStoneMeasurements"
                      type="text"
                    ></md-input>
                  </md-field>

                  <div class="color-code-wrapper">
                    <div class="color-code-label">Color Code</div>

                    <color-picker
                      @input="updateColorValue($event, 'SideStoneColorCode')"
                      v-model="jewelry.SideStoneColorCode"
                    ></color-picker>
                  </div>
                </md-card-content>

                <md-card-content v-else class="field-inputs">
                  <md-field
                    class="noFlex"
                    :class="{ 'md-invalid': errors['SideStoneType'] }"
                  >
                    <div class="static">
                      <p>Side Stone Type <span class="required">*</span></p>
                    </div>

                    <div style="margin-top: 50px">
                      <v-select
                        appendToBody
                        :options="stone_types"
                        v-model="jewelry.SideStoneType"
                      ></v-select>
                    </div>
                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field
                    :class="{ 'md-invalid': errors['SideStoneClarity'] }"
                    ref="SideStoneClarity"
                  >
                    <div class="color-select">
                      <div class="static"><p>Asterisks</p></div>
                      <div class="options">
                        <div
                          v-for="(asterisk, index) in asterisks"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :value="asterisk.value"
                            v-model="jewelry.SideStoneClarity.asterisks"
                          />{{ asterisk.name }}
                        </div>
                      </div>
                      <div class="static">
                        <p>
                          Side Stone Clarity
                          <span v-if="selectedTemplate != 14" class="required"
                            >*</span
                          >
                        </p>
                      </div>
                      <div style="margin-top: 50px">
                        <v-select
                          appendToBody
                          :options="clarity"
                          v-model="jewelry.SideStoneClarity.value"
                        >
                        </v-select>
                      </div>
                      <span class="md-error">Missing Field</span>
                    </div>
                  </md-field>

                  <md-field
                    :class="{ 'md-invalid': errors['SideStoneColor'] }"
                    class="noFlex"
                  >
                    <div class="color-select">
                      <div class="static"><p>Asterisks</p></div>
                      <div class="options">
                        <div
                          v-for="(asterisk, index) in asterisks"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :value="asterisk.value"
                            v-model="jewelry.SideStoneColor.asterisks"
                          />{{ asterisk.name }}
                        </div>
                      </div>
                      <div class="static">
                        <p>
                          Side Stone Color
                          <span
                            span
                            v-if="selectedTemplate != 14"
                            class="required"
                            >*</span
                          >
                        </p>
                      </div>
                      <div class="options">
                        <div
                          v-for="(color, index) in Object.keys(colors)"
                          :key="index"
                        >
                          <input
                            type="radio"
                            :value="color"
                            @change="
                              handleColorChanges('SideStoneColor', 'value')
                            "
                            v-model="jewelry.SideStoneColor.template"
                          />{{ color }}
                        </div>
                      </div>

                      <v-select
                        appendToBody
                        :options="colors[jewelry.SideStoneColor.template].value"
                        v-model="jewelry.SideStoneColor.value"
                      >
                      </v-select>
                      <span class="md-error">Missing Field</span>
                    </div>
                  </md-field>

                  <md-field
                    :class="{ 'md-invalid': errors['SideStoneShape'] }"
                    ref="SideStoneShape"
                    class="noFlex"
                  >
                    <div class="static">
                      <p>Side Stone Shape <span class="required">*</span></p>
                    </div>
                    <div style="margin-top: 50px">
                      <v-select
                        appendToBody
                        :options="shapes"
                        required
                        v-model="jewelry.SideStoneShape"
                      >
                      </v-select>
                    </div>

                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field
                    :class="{ 'md-invalid': errors['SideStoneCut'] }"
                    ref="SideStoneCut"
                    class="noFlex"
                    v-if="selectedTemplate == 1 || selectedTemplate == 4"
                  >
                    <div class="static"><p>Side Stone Cut</p></div>
                    <v-select
                      appendToBody
                      v-model="jewelry.SideStoneCut"
                      :options="cut"
                    >
                    </v-select>
                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <md-field
                    :class="{ 'md-invalid': errors['SideStoneWeight'] }"
                    ref="SideStoneWeight"
                    style="margin-top: 50px"
                  >
                    <label>Side Stone Carat Weight</label>

                    <md-input
                      :required="selectedTemplate != 14"
                      type="number"
                      v-model="jewelry.SideStoneWeight"
                    ></md-input>

                    <span class="md-error">Missing Field</span>
                  </md-field>

                  <div class="color-code-wrapper">
                    <div class="color-code-label">Color Code</div>

                    <color-picker
                      @input="updateColorValue($event, 'SideStoneColorCode')"
                      v-model="jewelry.SideStoneColorCode"
                    ></color-picker>
                  </div>
                </md-card-content>
              </md-card>
            </div>

            <div class="upload-description">
              <div class="ck-editor">
                <md-field>
                  <label>Description</label>
                  <!-- <CKEditor
                  @updateData="updateData($event, 'Description')"
                  :inputData="jewelry.Description"
                  :limitedCount="200"
                  :limitedText="true"
                /> -->
                  <md-textarea
                    @input="handleTextLimits('Description')"
                    v-model="jewelry.Description"
                  >
                  </md-textarea>
                </md-field>
                <sub
                  >{{ jewelry.Description.length }} of {{ limitedCount }} Max
                  Characters</sub
                >
                <label style="color: red" v-if="feedback.Description">{{
                  feedback.Description
                }}</label>
              </div>

              <div class="ck-editor">
                <md-field>
                  <label>Comments</label>
                  <md-textarea
                    @input="handleTextLimits('CertificateComments')"
                    v-model="jewelry.CertificateComments"
                  >
                  </md-textarea>
                </md-field>
                <sub
                  >{{ jewelry.CertificateComments.length }} of
                  {{ limitedCount }} Max Characters</sub
                >
                <label style="color: red" v-if="feedback.CertificateComments">{{
                  feedback.CertificateComments
                }}</label>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-card-actions v-if="selectedTemplate" md-alignment="space-between">
          <md-button class="md-success md-round md-small" @click="validate"
            >Save</md-button
          >
          <md-button
            class="md-primary md-round md-small"
            @click="validateThenSendToSpreadsheet"
            >Create Digital Certificate</md-button
          >

          <md-button class="md-primary md-round md-small" @click="handlePrint"
            >Print Card</md-button
          >
          <md-button class="md-danger md-round md-small" @click="handleDelete"
            >Delete</md-button
          >
        </md-card-actions>
      </md-card>
    </div>
    <modal v-if="showInfo" @close="showInfo = false">
      <template slot="body">
        <info-screen></info-screen>
      </template>
    </modal>
  </div>
</template>

<script>
import CKEditor from "@/components/CKEditor.vue";
import Upload from "@/components/Inputs/Upload.vue";
import { db, auth, functions, storage } from "@/config/firebaseInit.js";
import Swal from "sweetalert2";
import { Modal } from "@/components";
import JTemplatesInfoScreen from "@/components/JTemplatesInfoScreen";
import axios from "axios";
//import vSelect from "vue-select";

export default {
  components: {
    CKEditor,
    Upload,
    Modal,
    "info-screen": JTemplatesInfoScreen,
  },
  data() {
    return {
      selectedTab: 0,
      showInfo: false,
      gemStoneColors: [
        "Black",
        "Blue",
        "Bluish Green",
        "Bluish Purple",
        "Brown",
        "Brownish Orange",
        "Chameleon",
        "Colour Change",
        "Cornflower Blue",
        "Green-blue or blue-green",
        "Green",
        "Greenish Blue",
        "Grey",
        "Greenish Yellow",
        "Multi-color",
        "Orange",
        "Orangy Green",
        "Orangy Pink",
        "Orangy Red",
        "Orangy Yellow",
        "Padi",
        "Padparadscha",
        "Peach",
        "Pigeon Blood",
        "Pink",
        "Pinkish Orange",
        "Purple",
        "Purplish Pink",
        "Reddish Orange",
        "Reddish Purple",
        "Red-orange or orange-red",
        "Royal Purple",
        "Red-blue or purple-red",
        "Slightly yellowish green",
        "Strongly yellowish green",
        "Teal",
        "Very slightly bluish green",
        "Very strongly bluish blue",
        "Violet",
        "Violetish Blue",
        "Violetish Purple",
        "Watermelon",
        "White",
        "Yellow-Brown",
        "Yellow-Green",
        "Yellowish Orange",
        "Yellow-Orange",
      ],
      stone_types: [
        "Diamond",
        "Lab Grown Diamond",
        "Diamond Accent",
        "Mixed Colors",
        "Mixed Diamonds",
        "Agate",
        "Amethyst",
        "Andesine",
        "Apatite",
        "Aquamarine",
        "AUS Golden Pearl",
        "Beads",
        "Azurite",
        "Beryl",
        "Blue Topaz",
        "Black Agate",
        "Carnelian",
        "Chalcedony",
        "Citrine",
        "Conch",
        "Coral",
        "Demantoid",
        "Emerald",
        "Garnet",
        "Green Beryl",
        "Imperial Topaz",
        "Jade",
        "Jadeite",
        "Lapis Lazuli",
        "Malachite",
        "Moissanite",
        "Pearl",
        "Moonstone",
        "Mother of Pearl",
        "Pearl-Akoya",
        "None",
        "Opal",
        "Pearl-Baroque",
        "Pearl-Keshi",
        "Pearl-Mabe",
        "Pearl-South Sea",
        "Pearl-Tahitian",
        "Peridot",
        "Quartz",
        "Quartzite",
        "Ruby",
        "Sapphire",
        "Blue Sapphire",
        "Simulated Cat Eye",
        "Spinel",
        "Tanzanite",
        "Topaz",
        "Tourmaline",
        "Tsavorite",
        "Zircon",
        "Other Gemstone",
      ],
      templates: [
        {
          name: "Everything Round Center + Side Stones",
          id: 1,
        },
        {
          name: "Main Stones (No Center/Sides)",
          id: 2,
        },
        {
          name: "Main Stone Minimalist",
          id: 3,
        },
        {
          name: "Multiple Center Stones",
          id: 4,
        },
        {
          name: "Solitaire Minimalist",
          id: 5,
        },
        {
          name: "Round Center + Sides Stones (No Cut)",
          id: 6,
        },
        {
          name: "Main Stone Minimalist (No Measurements)",
          id: 7,
        },
        {
          name: "Main Stone (No Sides Stones)",
          id: 8,
        },
        {
          name: "Main Stone (No Side Stones & Cut)",
          id: 9,
        },
        {
          name: "Main Stone (1Color & 2Clarity)",
          id: 10,
        },
        {
          name: "Main Stone (2Color & 1Clarity)",
          id: 11,
        },
        {
          name: "Main Black Stone Minimalist",
          id: 12,
        },
        {
          name: "Multiple Center Stone (No Cut)",
          id: 13,
        },
        {
          name: "No Diamond (No Clarity and No Color)",
          id: 14,
        },
        {
          name: "Gem Stone as Primary Stone w/ SideStones (Gem or Diamonds)",
          id: 15,
        },
        {
          name: "Diamond Primary Stone and Gem Secondary Stone",
          id: 16,
        },
        {
          name: "Only Gem Stones",
          id: 17,
        },
        {
          name: "Metal Only",
          id: 19,
        },
        {
          name: "Gem Stones as Primary and Sidestones",
          id: 20,
        },
        {
          name: "Mixed Diamonds",
          id: 21,
        },
        {
          name: "Colored Diamonds + Main 4 and Colored SideStones",
          id: 22,
        },
        {
          name: "Expensive Colored Diamonds",
          id: 23,
        },
      ],
      metals: [
        "9k RG",
        "9k TG",
        "9k WG",
        "10k BG",
        "10k RG",
        "10k TG",
        "10k WG",
        "10k YG",
        "12k YG",
        "12k WG",
        "14k RG",
        "14k TT",
        "14k WG",
        "14k YG",
        "14k BKG",
        "18k RG",
        "18k TT",
        "18k WG",
        "18k YG",
        "22k WG",
        "23k YG",
        "24k WG",
        "24k WG",
        "Yellow Gold",
        "Platinum",
        "Rose Gold",
        "Platinum & 14k",
        "Platinum & 18k",
        "Plat & 18k YG",
        "Silver",
        "Titanium",
        "Silver/18k YG",
        "Stainless Steel",
        "SS/18k WG",
        "SS/18k RG",
        "SS/18k YG",
        "Bronze",
        "Palladium",
        "Tungsten",
        "Vermeil",
        "White Metal",
        "Other Metal",
      ],
      jewelry_type: [
        "Pendant",
        "Necklace",
        "Bracelet",
        "Hoops",
        "Ring",
        "Rings",
        "Earring",
        "Watch",
        "Solitaire",
        "Brooch",
        "Studs",
        "Watch Bezel",
        "Wedding Set",
      ],
      shapes: [
        "",
        "None",
        "Multi Shape",
        "Asscher",
        "Baguette",
        "Briolette",
        "CrissCut",
        "Cushion",
        "Emerald",
        "Fan",
        "Fancy Cut",
        "Flanders",
        "Fire-Rose",
        "Half Moon Cut",
        "Heart",
        "Kite",
        "Lozenge",
        "Marquise",
        "Old Cut",
        "Octagon",
        "Oval",
        "Pear",
        "Princess",
        "Radiant",
        "Rectangle Barrel Cut",
        "Rectangular",
        "Rose",
        "Round",
        // "Round Brilliant",
        "Shield",
        "Square",
        "Taper",
        "Tapered Baguette",
        "Teardrop",
        "Triangular",
        "Trillion Cut",
        "Trapeze Cut",
      ],
      companies: [],
      colors: {
        Standard: {
          name: "Standard",
          key: "",
          value: [
            "Z",
            "Y",
            "X",
            "W",
            "V",
            "U",
            "T",
            "S",
            "R",
            "Q",
            "P",
            "O",
            "N",
            "M",
            "L",
            "K",
            "J",
            "I",
            "H",
            "G",
            "F",
            "E",
            "D",
            "Blue",
            "Black",
            "Champagne",
            "Yellow",
            "Brown",
          ],
        },
        Blue: {
          name: "Blue",
          value: [
            "Light",
            "Fancy Light",
            "Fancy",
            "Fancy Intense",
            "Fancy Vivid",
          ],
          key: "_B_",
        },
        Brown: {
          name: "Brown",
          value: ["C1", "C2", "C3", "C4", "C5", "C6", "C7", "C8"],
          key: "_B_",
        },
        Green: {
          name: "Green",
          value: [
            "Faint",
            "Very Light",
            "Light",
            "Fancy Light",
            "Fancy",
            "Fancy Intense",
            "Fancy Vivid",
            "Fancy Deep",
          ],
          key: "_G_",
        },
        Pink: {
          name: "Pink",
          value: [
            "Faint",
            "Very Light",
            "Light",
            "Fancy Light",
            "Fancy",
            "Fancy Intense",
            "Fancy Vivid",
            "Fancy Deep",
          ],
          key: "_P_",
        },
        Yellow: {
          name: "Yellow",
          value: ["Light", "Fancy Light", "Fancy", "Intense", "Vivid", "Deep"],
          key: "_Y_",
        },
        "Yellow-Orange": {
          name: "Yellow-Orange",
          value: [
            "Light",
            "Fancy Light",
            "Fancy",
            "Fancy Vivid",
            "Intense",
            "Vivid",
            "Deep",
          ],
          key: "_YO_",
        },
        Black: {
          name: "Black",
          value: ["A", "AA", "AAA", "AAAA"],
          key: "_B_",
        },
        Red: {
          name: "Red",
          value: [
            "Faint",
            "Fancy",
            "Fancy Dark",
            "Fancy Intense",
            "Fancy Light",
            "Fancy Vivid",
            "Light",
            "Very Light",
          ],
          key: "_R_",
        },
        "Purplish-Red": {
          name: "Purplish-Red",
          value: [
            "Faint",
            "Fancy",
            "Fancy Dark",
            "Fancy Intense",
            "Fancy Light",
            "Fancy Vivid",
            "Light",
            "Very Light",
          ],
          key: "_PR_",
        },
      },
      cut: [
        "None",
        "Excellent",
        "Super Ideal",
        "Ideal",
        "Very Good",
        "Good",
        "Fair",
      ],
      clarity: [
        "",
        "I1",
        "I2",
        "I3",
        "SI",
        "SI1",
        "SI2",
        "SI3",
        "VS",
        "VS2",
        "VS1",
        "VVS2",
        "VVS1",
        "IF",
        "I2-I3",
        "IF-VS1",
        "VS1-VS2",
        "VVS2-VS1",
        "VS1-VS2",
        "VS2-SI1",
        "SI2-SI3",
        "INFLAWLESS",
        "FLAWLESS",
      ],
      polish: ["Poor", "Fair", "Good", "Very Good", "Excellent"],
      symmetry: ["Poor", "Fair", "Good", "Very Good", "Excellent"],
      fluorescence: ["Very Strong", "Strong", "Medium", "Faint", "None"],
      asterisks: [
        {
          name: "None",
          value: "",
        },
        {
          name: "*",
          value: "*",
        },
        {
          name: "**",
          value: "**",
        },
      ],
      loader: false,
      selectedColor: "#8AAE4DCC",
      selectedColorAsterisks: "",
      selectedColor2Asterisks: "",
      selectedColor3Asterisks: "",
      selectedSideStoneColorAsterisks: "",
      selectedClarityAsterisks: "",
      selectedClarity2Asterisks: "",
      selectedClarity3Asterisks: "",
      selectedColorTemplate: "Standard",
      selectedColor2Template: "Standard",
      selectedColor3Template: "Standard",
      selectedSideStoneColorTemplate: "Standard",
      selectedCompany: null,
      update_spreadsheet: true,
      errorMsg: "",
      jewelry: {
        Video: {
          name: null,
          ref: null,
          url: null,
        },
        Company: {
          id: "",
          name: "",
          logo: "",
        },
        JewelryType: "",
        CertNum: "",
        update_spreadsheet: true,
        Template: {},
        StockNum: "",
        ClientSKU: "",
        ProductType: "Jewelry",
        MainStoneMeasurements: "",
        MainStoneTable: "",
        MainStoneDepth: "",
        MainStonePolish: "",
        MainStoneSymmetry: "",
        MainStoneWeight: "",
        MainStoneClarity: {
          value: "",
          asterisks: "",
        },
        MainStoneColor: {
          template: "Standard",
          value: "",
          asterisks: "",
        },
        MainStoneClarity2: {
          value: "",
          asterisks: "",
        },
        MainStoneColor2: {
          template: "Standard",
          value: "",
          asterisks: "",
        },
        MainStoneCut: "",
        MainStoneShape: "",
        MainStoneType: "",
        MainStoneColorCode: "#FFFFFF",
        IsMultipleCenterStones: false,
        HasSideStones: false,
        SideStoneCut: "",
        SideStoneColor: {
          template: "Standard",
          asterisks: "",
          value: "",
        },
        SideStoneType: "",
        SideStoneColorCode: "#FFFFFF",
        SideStoneShape: "",
        SideStoneWeight: "",
        SideStoneClarity: {
          value: "",
          asterisks: "",
        },
        Description: "",
        CertificateComments: "",
        Inscription: "",
        UserID: "",
      },
      selectedTemplate: null,
      errors: {},
      limitedCount: 300,
      feedback: {
        CertificateComments: null,
        Description: null,
      },
    };
  },

  firestore() {
    return {
      companies: db.collection("companies"),
    };
  },

  async created() {
    this.getAttributes();
    await this.fetchItem();
  },

  methods: {
    handleCertSKUInfo(e, key) {
      this.jewelry[key] = e.target.value.replace(/\s+/g, '');
    },
    handlePrint() {
      let loader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
      });

      const item = JSON.parse(JSON.stringify(this.jewelry));

      let data = Object.assign(item, {
        CertNum: item.CertNum,
        CertificateComments: item.CertificateComments,
        CardDescription: item.CardDescription,
        MainStoneShape: item.MainStoneShape,
        SideStoneShape: item.SideStoneShape,
        MainStoneWeight: item.MainStoneWeight,
        SideStoneWeight: item.SideStoneWeight,
        Type: "Jewelry",
        ClientSKU: item.ClientSKU,
        ColoredStone: item.ColoredStone || false,
        MainStoneColor: `${item.MainStoneColor.value}${item.MainStoneColor.asterisks}`,
        MainStoneColor2: `${item.MainStoneColor2.value}${item.MainStoneColor2.asterisks}`,
        SideStoneColor: `${item.SideStoneColor.value}${item.SideStoneColor.asterisks}`,
        MainStoneClarity: `${item.MainStoneClarity.value}${item.MainStoneClarity.asterisks}`,
        MainStoneClarity2: `${item.MainStoneClarity2.value}${item.MainStoneClarity2.asterisks}`,
        SideStoneClarity: `${item.SideStoneClarity.value}${item.SideStoneClarity.asterisks}`,
        MainStoneCut: item.MainStoneCut,
        SideStoneCut: item.SideStoneCut,
        Metal: item.Metal,
        Measurement: item.Measurement,
      });

      return axios
        .post(
          "https://us-central1-diamonds-8cf72.cloudfunctions.net/disCertificateCardPrinterApi-updateDataTable",
          data
        )
        .then((res) => {
          Swal.fire("Data sent", "You can now print card", "success");
          loader.hide();
        })
        .catch((err) => {
          console.log(err);
          this.displayError(err);
          loader.hide();
        });
    },
    updateColorValue(val, item) {
      this.jewelry[item] = val.hex;
    },
    handleTextLimits(key) {
      if (this.jewelry[key].length >= this.limitedCount) {
        this.feedback[key] = "Can't exceed the max amount of characters";
        setTimeout(() => {
          this.feedback[key] = null;
        }, 1500);
        this.jewelry[key].substring(0, this.limitedCount);
      }
    },
    async fetchItem() {
      let jewelry = await db
        .doc(`jewelry/${this.$route.params.jewelry_id}`)
        .get();
      if (jewelry.exists) {
        this.jewelry = Object.assign(
          { id: jewelry.id },
          this.jewelry,
          jewelry.data()
        );
        this.formatItem(this.jewelry);
      }
    },
    formatItem(jewelry) {
      const colors = Object.values(this.colors).map((e) => e.key);
      const color_keys = Object.keys(this.colors);
      this.selectedCompany = jewelry.Company.id ? jewelry.Company.id : null;
      this.selectedTemplate = jewelry.Template.id ? jewelry.Template.id : null;

      if (!jewelry.hasOwnProperty("SideStoneMeasurements")) {
        this.jewelry.SideStoneMeasurements = "";
      }

      if (!jewelry.SideStoneShape) {
        this.jewelry.SideStoneShape = "";
      }

      if (!jewelry.MainStoneColorCode) {
        this.jewelry.MainStoneColorCode = this.getStoneColorCode(
          this.jewelry,
          "Main"
        );
      }

      if (!jewelry.SideStoneColorCode) {
        this.jewelry.SideStoneColorCode = this.getStoneColorCode(
          this.jewelry,
          "Side"
        );
      }

      if (!jewelry.SideStoneWeight) {
        this.jewelry.SideStoneWeight = "";
      }

      if (jewelry.HasSideStones && !jewelry.SideStoneType) {
        this.jewelry.SideStoneType = "Diamond";
      }

      if (!jewelry.hasOwnProperty("MainStoneColor2")) {
        this.jewelry.MainStoneColor2 = Object.assign({
          template: "Standard",
          asterisks: "",
          value: "",
        });
      }

      if (!jewelry.hasOwnProperty("MainStoneClarity2")) {
        this.jewelry.MainStoneClarity2 = {
          asterisks: "",
          value: "",
        };
      }

      if (jewelry.hasOwnProperty("selectedColorTemplate")) {
        let index = colors.indexOf(jewelry.selectedColorTemplate);
        this.selectedColorTemplate = color_keys[index]
          ? color_keys[index]
          : "Standard";
      }
      if (jewelry.hasOwnProperty("selectedColor2Template")) {
        let index = colors.indexOf(jewelry.selectedColor2Template);
        this.selectedColor2Template = color_keys[index]
          ? color_keys[index]
          : "Standard";
      }

      if (!jewelry.hasOwnProperty("SideStoneMeasurements")) {
        this.jewelry.SideStoneMeasurements = "";
      }

      if (!jewelry.hasOwnProperty("MainStoneType")) {
        this.jewelry.MainStoneType = "";
      }

      if (this.jewelry.Video == null) {
        this.jewelry.Video = { name: null };
      }

      if (jewelry.hasOwnProperty("selectedColor3Template")) {
        let index = colors.indexOf(jewelry.selectedColor3Template);
        this.selectedColor3Template = color_keys[index]
          ? color_keys[index]
          : "Standard";
      }

      if (jewelry.hasOwnProperty("selectedSideStoneColorTemplate")) {
        let index = colors.indexOf(jewelry.selectedSideStoneColorTemplate);
        this.selectedSideStoneColorTemplate = color_keys[index]
          ? color_keys[index]
          : "Standard";
      }

      if (jewelry.hasOwnProperty("selectedColorAsterisks")) {
        this.selectedColorAsterisks = this.jewelry.selectedColorAsterisks;
      }

      if (jewelry.hasOwnProperty("selectedColor2Asterisks")) {
        this.selectedColor2Asterisks = this.jewelry.selectedColor2Asterisks;
      }

      if (jewelry.hasOwnProperty("selectedColor3Asterisks")) {
        this.selectedColor3Asterisks = this.jewelry.selectedColor3Asterisks;
      }

      if (jewelry.hasOwnProperty("selectedSideStoneColorAsterisks")) {
        this.selectedSideStoneColorAsterisks =
          this.jewelry.selectedSideStoneColorAsterisks;
      }

      if (jewelry.hasOwnProperty("selectedClarityAsterisks")) {
        this.selectedClarityAsterisks = this.jewelry.selectedClarityAsterisks;
      }

      if (jewelry.hasOwnProperty("selectedClarity2Asterisks")) {
        this.selectedClarity2Asterisks = this.jewelry.selectedClarity2Asterisks;
      }
      if (jewelry.hasOwnProperty("selectedClarity3Asterisks")) {
        this.selectedClarity3Asterisks = this.jewelry.selectedClarity3Asterisks;
      }
    },
    getStoneColorCode(item, type) {
      const gemColors = require("./JSONs/GemColors.json");
      const diamondColors = require("./JSONs/DiamondColors.json");

      if (type === "Main") {
        if (item.MainStoneType.includes("Diamond")) {
          return diamondColors[item.MainStoneColor] || "#FFFFFF";
        } else {
          return gemColors[item.MainStoneType] || "#FFFFFF";
        }
      } else {
        if (item.SideStoneType.includes("Diamond")) {
          return diamondColors[item.SideStoneColor] || "#FFFFFF";
        } else {
          return gemColors[item.SideStoneType] || "#FFFFFF";
        }
      }
    },
    displayError(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log({ errorCode, errorMessage });
      return Swal.fire(
        "Error!",
        `${errorMessage} - code: ${errorCode}. Please try again or contact support.`,
        "error"
      );
    },
    forceUpdateItem() {
      this.$forceUpdate();
    },
    handleMultStones(e, created) {
      if (e) {
        if (created) {
          this.jewelry.MultipleCenterStones = [
            {
              MainStoneMeasurements: this.jewelry.MainStoneMeasurements,
              MainStoneTable: this.jewelry.MainStoneTable,
              MainStoneDepth: this.jewelry.MainStoneDepth,
              MainStonePolish: this.jewelry.MainStonePolish,
              MainStoneSymmetry: this.jewelry.MainStoneSymmetry,
              MainStoneWeight: this.jewelry.MainStoneWeight,
              MainStoneClarity: this.jewelry.MainStoneClarity,
              MainStoneColor: this.jewelry.MainStoneColor,
              MainStoneCut: this.jewelry.MainStoneCut,
              MainStoneShape: this.jewelry.MainStoneShape,
            },
          ];
        } else {
          this.jewelry.MultipleCenterStones = [
            {
              MainStoneMeasurements: "",
              MainStoneTable: "",
              MainStoneDepth: "",
              MainStonePolish: "",
              MainStoneSymmetry: "",
              MainStoneWeight: "",
              MainStoneClarity: {
                value: "",
                asterisks: "",
              },
              MainStoneColor: {
                template: "Standard",
                value: "",
                asterisks: "",
              },
              MainStoneClarity2: {
                value: "",
                asterisks: "",
              },
              MainStoneColor2: {
                template: "Standard",
                value: "",
                asterisks: "",
              },
              MainStoneCut: "",
              MainStoneShape: "",
            },
            {
              MainStoneMeasurements: "",
              MainStoneTable: "",
              MainStoneDepth: "",
              MainStonePolish: "",
              MainStoneSymmetry: "",
              MainStoneWeight: "",
              MainStoneClarity: {
                value: "",
                asterisks: "",
              },
              MainStoneColor: {
                template: "Standard",
                value: "",
                asterisks: "",
              },
              MainStoneClarity2: {
                value: "",
                asterisks: "",
              },
              MainStoneColor2: {
                template: "Standard",
                value: "",
                asterisks: "",
              },
              MainStoneCut: "",
              MainStoneShape: "",
            },
          ];
        }
      }
    },
    addCenterStone() {
      if (this.jewelry.MultipleCenterStones.length < 2) {
        this.jewelry.MultipleCenterStones.push({
          MainStoneMeasurements: "",
          MainStoneTable: "",
          MainStoneDepth: "",
          MainStonePolish: "",
          MainStoneSymmetry: "",
          MainStoneWeight: "",
          MainStoneClarity: {
            value: "",
            asterisks: "",
          },
          MainStoneColor: {
            template: "Standard",
            value: "",
            asterisks: "",
          },
          MainStoneClarity2: {
            value: "",
            asterisks: "",
          },
          MainStoneColor2: {
            template: "Standard",
            value: "",
            asterisks: "",
          },
          MainStoneCut: "",
          MainStoneShape: "",
        });
        this.selectedTab = this.jewelry.MultipleCenterStones.length - 1;
        this.$forceUpdate();
      } else {
        return Swal.fire(
          "Max Limit Reached",
          "You are only allowed to have 2 Center Stones",
          "error"
        );
      }
    },
    removeStone(i) {
      this.jewelry.MultipleCenterStones.splice(i, 1);
      this.selectedTab = this.jewelry.MultipleCenterStones.length - 1;
    },
    toggleTab(i) {
      this.selectedTab = i;
    },
    async getAttributes() {
      let attributes = await db.collection("attributes").get();
      attributes = attributes.docs.map((d) =>
        Object.assign({ id: d.id }, d.data())
      );
      attributes.forEach((att) => {
        if (att.id == "color") {
          this.colors.Standard.value = [...new Set(att.values)];
        } else if (this[att.id]) {
          this[att.id] = [...new Set([].concat(this[att.id], att.values))];
        }
      });
    },
    addSuffixAndExtension(str) {
      if (!str) return "";
      let suffix = "_001";
      let extension = ".mp4";

      if (!str.includes(suffix)) {
        str = str.replace(extension, "") + suffix;
      }

      if (!str.endsWith(extension)) {
        str += extension;
      }

      return str;
    },
    handleMeasurementsForMult(e, item, key) {
      let valid = true;
      const str = e.target.value;
      let measurements = str.replace(/mm/, "").split("x");
      measurements = measurements.map((n) => {
        n = n.trim();
        if (!Number(n)) valid = false;
        else return n;
      });
      if (valid) {
        item[key] = measurements.join(" x ") + " mm";
      } else {
        item[key] = "";
        return Swal.fire(
          "Invalid Format",
          "Please enter the correct format as shown on measurements",
          "warning"
        );
      }
    },
    handleMeasurements(e, key) {
      let valid = true;
      const str = e.target.value;
      let measurements = str.replace(/mm/, "").split("x");
      measurements = measurements.map((n) => {
        n = n.trim();
        if (!Number(n)) valid = false;
        else return n;
      });
      if (valid) {
        this.jewelry[key] = measurements.join(" x ") + " mm";
      } else {
        this.jewelry[key] = "";
        return Swal.fire(
          "Invalid Format",
          "Please enter the correct format as shown on measurements",
          "warning"
        );
      }
    },
    handleDelete() {
      const jewelry = db
        .collection("jewelry")
        .doc(this.$route.params.jewelry_id);
      return Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          jewelry.delete().then(() => {
            return Swal.fire(
              "Deleted!",
              "The data has been deleted.",
              "success"
            ).then(() => {
              return this.$router.go(-1);
            });
          });
        }
      });
    },

    updateData(payload, key) {
      this.jewelry[key] = payload;
    },
    handleColorChanges(key, subKey) {
      this.jewelry[key][subKey] = null;
    },
    handleColorChangesForMult(item) {
      item.MainStoneColor.value = null;
      this.$forceUpdate();
    },
    validate() {
      let valid = true;
      this.errorMsg = "";

      if (this.jewelry.IsMultipleCenterStones) {
        valid = this.validateMultipleCenterStone();
      } else {
        if (
          this.selectedTemplate == 1 ||
          this.selectedTemplate == 4 ||
          this.selectedTemplate == 9 ||
          this.selectedTemplate == 8
        ) {
          for (let k in this.jewelry) {
            if (
              k === "JewelryType" ||
              (k === "MainStoneCut" && this.selectedTemplate != 9) ||
              k === "MainStoneShape" ||
              k === "MainStonePolish" ||
              k === "MainStoneSymmetry" ||
              (k === "MainStoneWeight" &&
                !this.jewelry.MainStoneType.includes("Accent"))
            ) {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            } else if (k === "MainStoneColor" || k === "MainStoneClarity") {
              if (!this.jewelry[k].value) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        } else if (this.selectedTemplate == 2) {
          for (let k in this.jewelry) {
            if (k === "JewelryType" || k === "MainStoneWeight") {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            } else if (
              k === "MainStoneColor" ||
              k === "MainStoneClarity" ||
              k === "MainStoneColor2" ||
              k === "MainStoneClarity2"
            ) {
              if (!this.jewelry[k].value) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        } else if (this.selectedTemplate == 3) {
          for (let k in this.jewelry) {
            if (k === "JewelryType" || k === "MainStoneShape") {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";

                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            } else if (k === "MainStoneColor" || k === "MainStoneClarity") {
              if (!this.jewelry[k].value) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        } else if (this.selectedTemplate == 5) {
          for (let k in this.jewelry) {
            if (
              k === "JewelryType" ||
              k === "MainStoneShape" ||
              (k === "MainStoneWeight" &&
                !this.jewelry.MainStoneType.includes("Accent"))
            ) {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            } else if (k === "MainStoneColor" || k === "MainStoneClarity") {
              if (!this.jewelry[k].value) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        } else if (this.selectedTemplate == 6) {
          for (let k in this.jewelry) {
            if (
              k === "JewelryType" ||
              k === "MainStoneShape" ||
              k === "MainStonePolish" ||
              k === "MainStoneSymmetry" ||
              (k === "MainStoneWeight" &&
                !this.jewelry.MainStoneType.includes("Accent"))
            ) {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            } else if (k === "MainStoneColor" || k === "MainStoneClarity") {
              if (!this.jewelry[k].value) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        } else if (this.selectedTemplate == 10) {
          for (let k in this.jewelry) {
            if (
              k === "JewelryType" ||
              k === "MainStoneShape" ||
              (k === "MainStoneWeight" &&
                !this.jewelry.MainStoneType.includes("Accent"))
            ) {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            } else if (
              k === "MainStoneColor" ||
              k === "MainStoneClarity" ||
              k === "MainStoneClarity2"
            ) {
              if (!this.jewelry[k].value) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        } else if (this.selectedTemplate == 11) {
          for (let k in this.jewelry) {
            if (
              k === "JewelryType" ||
              k === "MainStoneShape" ||
              (k === "MainStoneWeight" &&
                !this.jewelry.MainStoneType.includes("Accent"))
            ) {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            } else if (
              k === "MainStoneColor" ||
              k === "MainStoneClarity" ||
              k === "MainStoneColor2"
            ) {
              if (!this.jewelry[k].value) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        } else if (this.selectedTemplate == 14) {
          for (let k in this.jewelry) {
            if (k === "JewelryType" || k === "MainStoneShape") {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        } else if (this.selectedTemplate == 22) {
          for (let k in this.jewelry) {
            if (
              k === "JewelryType" ||
              k === "MainStoneType" ||
              k === "MainStoneShape" ||
              (k === "MainStoneWeight" &&
                !this.jewelry.MainStoneType.includes("Accent"))
            ) {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            } else if (k === "MainStoneColor") {
              if (!this.jewelry[k].value) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }

            if (this.jewelry.HasSideStones) {
              if (
                k === "SideStoneType" ||
                k === "SideStoneShape" ||
                (k === "SideStoneWeight" &&
                  !this.jewelry.SideStoneType.includes("Accent"))
              ) {
                if (!this.jewelry[k]) {
                  this.errorMsg += k + " ";
                  this.errors[k] = true;
                  valid = false;
                } else {
                  this.errors[k] = false;
                }
              } else if (k === "SideStoneColor" || k === "SideStoneClarity") {
                if (!this.jewelry[k].value) {
                  this.errorMsg += k + " ";
                  this.errors[k] = true;
                  valid = false;
                } else {
                  this.errors[k] = false;
                }
              }
            }
          }
        }
      }

      if (!this.jewelry.ClientSKU && !this.jewelry.CertNum) {
        return Swal.fire(
          "Error!",
          "Must enter a Client SKU or a Certificate Number",
          "error"
        );
      }

      if (valid) {
        this.save();
      } else {
        this.$forceUpdate();
        return Swal.fire(
          "Error!",
          "Please enter all required fields ( in red )",
          "error"
        );
      }
    },
    validateThenSendToSpreadsheet() {
      let valid = true;
      this.errorMsg = "";

      if (this.jewelry.IsMultipleCenterStones) {
        valid = this.validateMultipleCenterStone();
      } else {
        if (
          this.selectedTemplate == 1 ||
          this.selectedTemplate == 4 ||
          this.selectedTemplate == 9 ||
          this.selectedTemplate == 8
        ) {
          for (let k in this.jewelry) {
            if (
              k === "JewelryType" ||
              (k === "MainStoneCut" && this.selectedTemplate != 9) ||
              k === "MainStoneShape" ||
              k === "MainStonePolish" ||
              k === "MainStoneSymmetry" ||
              (k === "MainStoneWeight" &&
                !this.jewelry.MainStoneType.includes("Accent"))
            ) {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            } else if (k === "MainStoneColor" || k === "MainStoneClarity") {
              if (!this.jewelry[k].value) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        } else if (this.selectedTemplate == 2) {
          for (let k in this.jewelry) {
            if (k === "JewelryType" || k === "MainStoneWeight") {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            } else if (
              k === "MainStoneColor" ||
              k === "MainStoneClarity" ||
              k === "MainStoneColor2" ||
              k === "MainStoneClarity2"
            ) {
              if (!this.jewelry[k].value) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        } else if (this.selectedTemplate == 3) {
          for (let k in this.jewelry) {
            if (k === "JewelryType" || k === "MainStoneShape") {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";

                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            } else if (k === "MainStoneColor" || k === "MainStoneClarity") {
              if (!this.jewelry[k].value) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        } else if (this.selectedTemplate == 5) {
          for (let k in this.jewelry) {
            if (
              k === "JewelryType" ||
              k === "MainStoneShape" ||
              (k === "MainStoneWeight" &&
                !this.jewelry.MainStoneType.includes("Accent"))
            ) {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            } else if (k === "MainStoneColor" || k === "MainStoneClarity") {
              if (!this.jewelry[k].value) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        } else if (this.selectedTemplate == 6) {
          for (let k in this.jewelry) {
            if (
              k === "JewelryType" ||
              k === "MainStoneShape" ||
              k === "MainStonePolish" ||
              k === "MainStoneSymmetry" ||
              (k === "MainStoneWeight" &&
                !this.jewelry.MainStoneType.includes("Accent"))
            ) {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            } else if (k === "MainStoneColor" || k === "MainStoneClarity") {
              if (!this.jewelry[k].value) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        } else if (this.selectedTemplate == 10) {
          for (let k in this.jewelry) {
            if (
              k === "JewelryType" ||
              k === "MainStoneShape" ||
              (k === "MainStoneWeight" &&
                !this.jewelry.MainStoneType.includes("Accent"))
            ) {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            } else if (
              k === "MainStoneColor" ||
              k === "MainStoneClarity" ||
              k === "MainStoneClarity2"
            ) {
              if (!this.jewelry[k].value) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        } else if (this.selectedTemplate == 11) {
          for (let k in this.jewelry) {
            if (
              k === "JewelryType" ||
              k === "MainStoneShape" ||
              (k === "MainStoneWeight" &&
                !this.jewelry.MainStoneType.includes("Accent"))
            ) {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            } else if (
              k === "MainStoneColor" ||
              k === "MainStoneClarity" ||
              k === "MainStoneColor2"
            ) {
              if (!this.jewelry[k].value) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        } else if (this.selectedTemplate == 14) {
          for (let k in this.jewelry) {
            if (k === "JewelryType" || k === "MainStoneShape") {
              if (!this.jewelry[k]) {
                this.errorMsg += k + " ";
                this.errors[k] = true;
                valid = false;
              } else {
                this.errors[k] = false;
              }
            }
          }
        }
      }

      if (!this.jewelry.ClientSKU && !this.jewelry.CertNum) {
        return Swal.fire(
          "Error!",
          "Must enter a Client SKU or a Certificate Number",
          "error"
        );
      }

      if (valid) {
        this.createDigitalCert();
      } else {
        this.$forceUpdate();
        return Swal.fire(
          "Error!",
          "Please enter all required fields ( in red )",
          "error"
        );
      }
    },
    validateMultipleCenterStone() {
      let valid = true;
      this.jewelry.MultipleCenterStones.forEach((item) => {
        for (const k in item) {
          if (
            k === "MainStoneShape" ||
            k === "MainStonePolish" ||
            k === "MainStoneSymmetry" ||
            (k === "MainStoneWeight" &&
              !this.jewelry.MainStoneType.includes("Accent"))
          ) {
            if (!item[k]) {
              this.errorMsg += k + " ";
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          } else if (k === "MainStoneColor" || k === "MainStoneClarity") {
            if (!item[k].value) {
              this.errorMsg += k + " ";
              this.errors[k] = true;
              valid = false;
            } else {
              this.errors[k] = false;
            }
          }
        }
      });

      for (const k in this.jewelry) {
        if (k === "CertNum" || k === "JewelryType") {
          if (!this.jewelry[k]) {
            this.errorMsg += k + " ";
            this.errors[k] = true;
            valid = false;
          } else {
            this.errors[k] = false;
          }
        }
      }
      return valid;
    },

    uploadedMedia(payload, key) {
      if (key == "CertificateVideo") this.update_spreadsheet = false;
      if (this.jewelry.addedBarcode) this.jewelry.addedBarcode = false;
      this.jewelry[key] = payload.media;
    },

    removeMedia(key) {
      if (!this.jewelry[key].ref) {
        this.jewelry[key] = null;
        return;
      }
      const imageRef = storage.ref(this.jewelry[key].ref);
      // Delete the file
      imageRef
        .delete()
        .then(() => {
          this.jewelry[key] = null;
        })
        .catch((error) => {
          this.displayError(error);
        });
    },

    async save() {
      let loader = this.$loading.show({
        container: this.$refs.loader,
        canCancel: false,
      });

      if (!this.jewelry.created) {
        this.jewelry.created = Date.now();
        this.jewelry.clicks = 0;
      }

      if (
        !this.jewelry.MainStoneType.includes("Diamond") ||
        !this.jewelry.SideStoneType.includes("Diamond")
      )
        this.jewelry.ColoredStone = true;
      else this.jewelry.ColoredStone = false;

      this.jewelry.Video.name = this.addSuffixAndExtension(
        this.jewelry.Video.name
      );

      this.jewelry.UserID = auth.currentUser.uid;
      if (this.jewelry.NewlyAdded) this.jewelry.NewlyAdded = false;

      await this.formatDescription(this.jewelry.Description);
      const jewelryRef = db.doc(
        `jewelry/${
          this.jewelry.CertNum
            ? this.jewelry.CertNum
            : this.$route.params.jewelry_id
        }`
      );

      try {
        await jewelryRef.set(this.jewelry, { merge: true });
        loader.hide();
      } catch (error) {
        this.displayError(error);
        loader.hide();
      }

      return Swal.fire({
        title: "Success",
        text: "Certificate has been saved",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Go Back",
      }).then((result) => {
        if (result.value) {
          this.$router.push("/jewelry");
        }
      });

      // await jewelryRef
      //   .set(this.jewelry, { merge: true })
      //   .then(async () => {
      //     if (this.jewelry.CertificateVideo && !this.jewelry.addedBarcode) {
      //       try {
      //         const addJewelry = functions.httpsCallable("addJewelryToUriApi");
      //         return addJewelry({
      //           barcode: this.jewelry.ClientSKU,
      //           url: this.jewelry.CertificateVideo.url,
      //           customer: this.jewelry.Company.name || "NONE",
      //         })
      //           .then((res) => {
      //             return jewelryRef.update({ addedBarcode: true });
      //           })
      //           .then(() => loader.hide())
      //           .catch((err) => {
      //             loader.hide();
      //             throw err;
      //           });
      //       } catch (error) {
      //         loader.hide();
      //         console.log(error);
      //         return error;
      //       }
      //     }
      //     loader.hide();
      //     return true;
      //   })
      //   .catch((error) => {
      //     loader.hide();
      //     this.displayError(error);
      //   });

      // return Swal.fire("Success", "Jewelry has been saved", "success").then(
      //   () => {
      //     this.$router.push("/jewelry");
      //   }
      // );
    },

    async createDigitalCert() {
      let loader = this.$loading.show({
        container: this.$refs.loader,
        canCancel: false,
      });

      if (!this.jewelry.created) {
        this.jewelry.created = Date.now();
        this.jewelry.clicks = 0;
      }

      this.jewelry.Video.name = this.addSuffixAndExtension(
        this.jewelry.Video.name
      );

      this.jewelry.UserID = auth.currentUser.uid;
      this.jewelry.update_spreadsheet = true;
      this.jewelry.updatedAt = Date.now();
      if (this.jewelry.NewlyAdded) this.jewelry.NewlyAdded = false;

      await this.formatDescription(this.jewelry.Description);
      const jewelryRef = db.doc(
        `jewelry/${
          this.jewelry.CertNum
            ? this.jewelry.CertNum
            : this.$route.params.jewelry_id
        }`
      );

      try {
        await jewelryRef.set(this.jewelry, { merge: true });
        loader.hide();
      } catch (error) {
        this.displayError(error);
        loader.hide();
      }

      return Swal.fire(
        "Success",
        "Certificate has been saved and sent to google spreadsheet",
        "success"
      ).then(() => {
        this.$router.push("/jewelry");
      });
    },

    formatDescription(description) {
      if (!description) return;
      let str = description.toString();
      str = str.replace(/<[^>]*>/g, "");
      this.jewelry.Description = str;
      return str;
    },
  },
  watch: {
    selectedCompany(v) {
      if (this.selectedCompany) {
        let company = this.companies.find((c) => c.id === this.selectedCompany);
        this.jewelry.Company = {
          id: company.id,
          name: company.name,
          logo: company.images,
        };
      }
    },
    selectedTemplate(nVal, oVal) {
      if (this.selectedTemplate) {
        this.errors = {};
        let template = this.templates.find(
          (t) => t.id == this.selectedTemplate
        );
        this.jewelry.Template = template;

        if (nVal == 2) {
          if (!this.jewelry.hasOwnProperty("MainStoneColor2")) {
            this.jewelry.MainStoneColor2 = {
              template: "Standard",
              asterisks: "",
              value: "",
            };
          }
          if (!this.jewelry.hasOwnProperty("MainStoneClarity2")) {
            this.jewelry.MainStoneClarity2 = {
              asterisks: "",
              value: "",
            };
          }
          return true;
        }

        if (nVal == 4 || nVal == 13) {
          this.jewelry.HasSideStones = false;
          this.jewelry.IsMultipleCenterStones = true;
          if (
            !this.jewelry.created ||
            (this.jewelry.created && !this.jewelry.MultipleCenterStones)
          )
            this.handleMultStones(true, false);
          else if (
            this.jewelry.created &&
            this.jewelry.MainStoneColor.value &&
            (!this.jewelry.MultipleCenterStones ||
              !this.jewelry.MultipleCenterStones[0].MainStoneColor.value)
          )
            this.handleMultStones(true, true);
          return true;
        }

        if (this.selectedTemplate != 4 || this.selectedTemplate != 13) {
          this.jewelry.IsMultipleCenterStones = false;
        }

        if (nVal == 10) {
          this.jewelry.HasSideStones = false;
          this.jewelry.IsMultipleCenterStones = false;
        }

        if (nVal == 5) {
          this.jewelry_type = ["Pendant", "Necklace", "Ring"];
          return true;
        } else if (nVal != 5) {
          this.jewelry_type = [
            "Pendant",
            "Hoops",
            "Necklace",
            "Bracelet",
            "Ring",
            "Rings",
            "Earring",
            "Watch",
            "Solitaire",
            "Brooch",
            "Studs",
            "Watch Bezel",
            "Wedding Set",
          ];
          this.$forceUpdate();
          return true;
        }

        if (this.selectedTemplate == 20) {
          this.jewelry.HasSideStones = true;
          this.jewelry.IsMultipleCenterStones = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.highlighted {
  background-color: green !important;
}
.content_wrapper {
  padding: 20px;
  border: 1px solid gray;
  margin-top: 20px;
  border-radius: 5px;
}
.stone-options {
  display: flex;
  background-color: #96a3c530;
  border-radius: 5px;
  padding: 10px 20px;
}

.tabs {
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 125px;
}

.tab {
  width: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #707a98;
  padding: 5px 0;
  border-radius: 5px;
  margin-right: 5px;
}

.rmvTab {
  /* width: 100%;
  display: flex;
  justify-content: flex-end; */
  position: absolute;
  margin-top: -20px;
  margin-left: 34px;
  color: #ff5252;
}

.md-divider {
  margin: 20px 0 20px 0;
}
.md-error {
  height: 75px !important;
}

.md-field.md-required label:after {
  color: red !important;
}
.field-inputs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .md-field {
    width: 48%;
  }
  .switch {
    width: 48%;
    display: flex;
    .switch-flex {
      margin-right: 50px;
    }
  }
}

.ck-editor {
  width: 45%;
}

.color-code-wrapper {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: first baseline;
  flex-direction: column;
  padding-left: 45px;
}

.color-code-label {
  text-align: center;
  width: 25%;
}
.upload-description {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.upload-parent {
  width: 100%;
}

.t-select {
  width: 75%;
  border: 0.5px solid rgba(170, 172, 172, 0.742);
}

.t-label {
  width: 150px;
}

.t-container {
  display: flex;
}

.noFlex {
  display: block;
}
</style>
