import { render, staticRenderFns } from "./JewelryDetails.vue?vue&type=template&id=4ce676aa&scoped=true"
import script from "./JewelryDetails.vue?vue&type=script&lang=js"
export * from "./JewelryDetails.vue?vue&type=script&lang=js"
import style0 from "./JewelryDetails.vue?vue&type=style&index=0&id=4ce676aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ce676aa",
  null
  
)

export default component.exports