<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card class="light">
        <md-card-content>
          <div class="search-options">
            <label for="">Search For: </label>
            <v-select
              :options="searchOptions"
              v-model="selectedSearchOption"
              label="name"
              :clearable="false"
            >
            </v-select>
          </div>
          <md-field class="search-bar">
            <md-input
              type="search"
              clearable
              :placeholder="`Search records by ${selectedSearchOption.name}`"
              v-model="filterBy.search"
              @input="upperCaseText"
              @keypress="evokeFilter($event)"
            ></md-input>
          </md-field>
          <div class="filters">
            <div class="filter">
              <p>Start From:</p>
              <md-datepicker
                class="datepicker"
                @input="validateDateRange('startDate')"
                v-model="filterBy.startDate"
              />
            </div>
            <div class="filter">
              <p>End To:</p>
              <md-datepicker
                class="datepicker"
                @input="validateDateRange('endDate')"
                v-model="filterBy.endDate"
              />
            </div>
            <div class="filter">
              <p>Filter By Template</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.templates"
                :options="templates"
                label="name"
                :reduce="(t) => t.id"
                :closeOnSelect="false"
              >
                <template v-slot:option="option">
                  {{ option.id }}: {{ option.name }}
                </template>
              </v-select>
            </div>
            <div class="filter">
              <p>Filter By Style</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.style"
                :options="jewelry_type"
                :closeOnSelect="false"
              >
              </v-select>
            </div>

            <div class="filter">
              <p>Filter By Client</p>
              <v-select
                :appendToBody="true"
                :deselectFromDropdown="true"
                v-model="filterBy.companies"
                :options="companies"
                label="name"
                :reduce="(p) => p.id"
                :closeOnSelect="false"
              >
              </v-select>
            </div>
            <!-- <div class="filter">
              <md-checkbox v-model="showItemsWithVideo">
                <span class="span-checkbox"
                  >Show Only With Cert Video</span
                ></md-checkbox
              >
              <md-checkbox v-model="showItemsWithoutVideo"
                ><span class="span-checkbox"
                  >Show Without Cert Video</span
                ></md-checkbox
              >
            </div> -->

            <div
              class="flex flex-row justify-content-between"
              style="width: 300px; bottom: 5px; position: absolute; right: 0"
            >
              <md-button
                style="background-color: green !important"
                class="text-btn"
                @click="handleFilter"
                >Filters</md-button
              >
              <md-button
                style="background-color: red !important"
                class="text-btn"
                @click="clearFilters"
                >Clear Filters</md-button
              >
            </div>
          </div>
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-content>
          <div v-if="!showSelectableTable" class="btn-row stats_holder">
            <!-- <md-button class="md-primary md-round md-small" @click="exportQRCSV"
              >Export for QR</md-button
            >
            <md-button class="md-primary md-round md-small" @click="exportCSV"
              >Export</md-button
            > -->
            <!-- <md-button
              class="md-primary md-round md-small"
              @click="promptFileUpload"
              >Import</md-button
            > -->
            <!-- <input
              style="display: none"
              id="inputFile"
              type="file"
              @input="importCSV"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            /> -->
            <md-button
              style="margin-left: 10px"
              class="md-success md-small"
              @click="newJewelry"
              >New Certificate</md-button
            >
            <md-button
              style="margin-left: 10px"
              class="md-primary md-small"
              @click="showPopup = true"
              >Add From Diamond Track</md-button
            >

            <md-button
              style="margin-left: 10px"
              class="md-warning md-small"
              @click="showSelectableTable = true"
              >Print Multiple</md-button
            >

            <md-button
              style="margin-left: 10px"
              class="md-danger md-small"
              @click="clearCertCardDB"
              >Clear Cert Card DB / Asure ID</md-button
            >
          </div>

          <md-table
            v-if="!showSelectableTable"
            md-fixed-header
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
            id="jewelry-table"
          >
            <md-table-toolbar :style="{ marginBottom: '20px' }">
              <md-field>
                <label for="jewelry">Per page</label>
                <md-select
                  @change="fetchData('per-page')"
                  v-model="pagination.perPage"
                  name="jewelry"
                >
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                    >{{ item }}</md-option
                  >
                </md-select>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Intake" md-sort-by="created">{{
                item.created | format_date
              }}</md-table-cell>

              <md-table-cell md-label="Client">{{
                item.Company.name || "N/A"
              }}</md-table-cell>

              <md-table-cell md-label="Customer">{{
                item.Customer || "N/A"
              }}</md-table-cell>

              <md-table-cell md-label="Order #" md-sort-by="created"
                >{{ item.OrderNum || "N/A"
                }}{{ item.LineNum ? `-${item.LineNum}` : "" }}</md-table-cell
              >

              <md-table-cell md-label="Certificate #" md-sort-by="CertNum">{{
                item.CertNum || "N/A"
              }}</md-table-cell>

              <md-table-cell md-label="Client SKU" md-sort-by="ClientSKU">{{
                item.ClientSKU || "N/A"
              }}</md-table-cell>

              <md-table-cell md-label="Template" md-sort-by="Template.name">{{
                item.Template.name
              }}</md-table-cell>

              <md-table-cell md-label="Style" md-sort-by="JewelryType">{{
                item.JewelryType
              }}</md-table-cell>

              <!-- <md-table-cell md-label="Published">
                <md-switch
                  @change="published(item.published, item.id)"
                  v-model="item.published"
                  :value="true"
                >{{item.published ? 'Yes' : 'No'}}</md-switch>
              </md-table-cell>-->
              <md-table-cell md-label="Controls">
                <md-button
                  @click="openLink(item)"
                  class="md-just-icon md-warning md-simple"
                >
                  <md-icon>link</md-icon>
                  <md-tooltip>View Digital Certificate</md-tooltip>
                </md-button>

                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="handleModal(item)"
                >
                  <md-icon class="md-success">visibility</md-icon>
                  <md-tooltip md-direction="bottom"
                    >Quick Preview</md-tooltip
                  > </md-button
                ><md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="handleEdit(item)"
                >
                  <md-icon>edit</md-icon>
                  <md-tooltip>Go To Detail Page</md-tooltip>
                </md-button>
                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="handlePrint(item, false)"
                >
                  <md-icon>print</md-icon>
                  <md-tooltip>Print Card Certificate</md-tooltip>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>

          <SelectableTable
            v-else
            @onSelected="onSelected"
            :tableData="queriedData"
            :tableInfo="selectedTableHeaders"
          >
            <template v-slot:btns>
              <div style="display: flex; flex-direction: column">
                <div>
                  <p class="required">Select The Certs Being Shipped Today</p>
                </div>
                <div>
                  <md-button
                    class="md-success md-small"
                    @click="handleMultiplePrints"
                    >Print</md-button
                  >
                  <md-button
                    class="md-warning"
                    @click="
                      () => {
                        selectedItemToPrint = [];
                        showSelectableTable = false;
                      }
                    "
                    >Cancel</md-button
                  >
                </div>
              </div>
            </template>
          </SelectableTable>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <Pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
            @update-table="fetchData"
            :allowClick="Boolean(tableData.length + searchedData.length)"
          ></Pagination>
        </md-card-actions>
      </md-card>
    </div>
    <popup
      @fetch-data="fetchData('start')"
      @close="showPopup = false"
      :showPopup="showPopup"
      itemType="Jewelry"
    />

    <!-- Modal for Quick Preview -->
    <modal v-if="showPreviewModal" @close="closeModal">
      <template slot="header">
        <h3>Previewing {{ selectedItem.CertNum }}</h3>
      </template>
      <template slot="body">
        <jewelry-detail-preview
          :jewelry="selectedItem"
        ></jewelry-detail-preview>
      </template>
    </modal>
  </div>
</template>

<script>
import { Pagination, Modal, SelectableTable } from "@/components";
import { db, batch } from "@/config/firebaseInit";
import Swal from "sweetalert2";
import router from "@/router";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import Popup from "@/components/InventoryPopup.vue";
import moment from "moment";
import axios from "axios";
import JewelryDetailPreview from "./JewelryDetailPreview.vue";

export default {
  components: {
    Pagination,
    popup: Popup,
    Modal,
    JewelryDetailPreview,
    SelectableTable,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData.slice(this.from, this.to);
      }
      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableSize;
    },
  },
  data() {
    return {
      showPopup: false,
      showSelectableTable: false,
      showPreviewModal: false,
      currentSort: "name",
      currentSortOrder: "asc",
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 250, 500],
        total: 0,
      },
      searchOptions: [
        {
          name: "Order #",
          key: "OrderNum",
        },
        {
          name: "Cert #",
          key: "CertNum",
        },
        {
          name: "Client SKU",
          key: "ClientSKU",
        },
        {
          name: "Item #",
          key: "ItemNum",
        },
        {
          name: "PO #",
          key: "PurchaseOrder",
        },
        {
          name: "Tracking #",
          key: "TrackingNumber",
        },
      ],
      selectedSearchOption: {
        name: "Cert #",
        key: "CertNum",
      },
      originPath: null,
      selectedItem: {},
      tableSize: 0,
      searchQuery: "",
      searchVal: "",
      jewelry_type: [],
      filterBy: {
        style: "",
        templates: "",
        companies: "",
        startDate: null,
        endDate: null,
        search: "",
      },
      propsToSearch: ["title", "fullname", "description"],
      templates: [
        {
          name: "Everything Round Center + Side Stones",
          id: 1,
        },
        {
          name: "Main Stones (No Center/Sides)",
          id: 2,
        },
        {
          name: "Main Stone Minimalist",
          id: 3,
        },
        {
          name: "Multiple Center Stones",
          id: 4,
        },
        {
          name: "Solitaire Minimalist",
          id: 5,
        },
        {
          name: "Round Center + Sides Stones (No Cut)",
          id: 6,
        },
        {
          name: "Main Stone Minimalist (No Measurements)",
          id: 7,
        },
        {
          name: "Main Stone (No Sides Stones)",
          id: 8,
        },
        {
          name: "Main Stone (No Side Stones & Cut)",
          id: 9,
        },
        {
          name: "Main Stone (1Color & 2Clarity)",
          id: 10,
        },
        {
          name: "Main Stone (2Color & 1Clarity)",
          id: 11,
        },
        {
          name: "Main Black Stone Minimalist",
          id: 12,
        },
        {
          name: "Multiple Center Stone (No Cut)",
          id: 13,
        },
        {
          name: "No Diamond (No Clarity and No Color)",
          id: 14,
        },
        {
          name: "Gem Stone as Primary Stone w/ SideStones (Gem or Diamonds)",
          id: 15,
        },
        {
          name: "Diamond Primary Stone and Gem Secondary Stone",
          id: 16,
        },
        {
          name: "Only Gem Stones",
          id: 17,
        },
        {
          name: "Metal Only",
          id: 19,
        },
        {
          name: "Gem Stones as Primary and Sidestones",
          id: 20,
        },
        {
          name: "Mixed Diamonds",
          id: 21,
        },
        {
          name: "Colored Diamonds + Main 4 and Colored SideStones",
          id: 22,
        },
        {
          name: "Expensive Colored Diamonds",
          id: 23,
        },
      ],
      showItemsWithVideo: false,
      showItemsWithoutVideo: false,
      jewelry_type: [
        "Pendant",
        "Necklace",
        "Bracelet",
        "Ring",
        "Rings",
        "Earring",
        "Watch",
        "Solitaire",
        "Brooch",
        "Studs",
        "Watch Bezel",
        "Wedding Set",
      ],
      shapes: [
        "None",
        "Asscher",
        "Baguette",
        "Cushion",
        "Heart",
        "Marquise",
        "Oval",
        "Pear",
        "Princess",
        "Radiant",
        "Rose",
        "Shield",
        "Taper",
        "Triangular",
        "Round",
        "Marquise",
        "Emerald",
        "Old Cut",
        "Triangular Cut",
        "Baguette Cut",
        "Trapeze Cut",
        "Shield Cut",
        "Taper Cut",
        "Half Moon Cut",
        "Rose Cut",
      ],
      colors: [
        "Z",
        "Y",
        "X",
        "W",
        "V",
        "U",
        "T",
        "S",
        "R",
        "Q",
        "P",
        "O",
        "N",
        "M",
        "L",
        "K",
        "J",
        "I",
        "H",
        "G",
        "F",
        "E",
        "D",
        "A",
        "AA",
        "AAA",
        "AAAA",
        "C1",
        "C2",
        "C3",
        "C4",
        "C5",
        "C6",
        "C7",
        "C8",
        "Faint",
        "Very Light",
        "Light",
        "Intense",
        "Vivid",
        "Deep",
        "Fancy Light",
        "Fancy",
        "Fancy Intense",
        "Fancy Vivid",
        "Fancy Deep",
      ],
      selectedItemToPrint: [],
      tableData: [],
      selectedTableHeaders: [
        {
          label: "Certificate #",
          key: "CertNum",
        },
        {
          label: "Client",
          key: "Company",
          subKey: "name",
        },
        {
          label: "Order Number",
          key: "OrderNum",
        },
        {
          label: "Line Number",
          key: "LineNum",
        },
        {
          label: "Client SKU",
          key: "ClientSKU",
        },
        {
          label: "Item Type",
          key: "JewelryType",
        },
      ],
      unfilteredItems: [],
      searchedData: [],
      companies: [],
      fuseSearch: null,
      routeChange: true,
      certificateRef: null,
    };
  },
  firestore() {
    return {
      companies: db.collection("companies"),
    };
  },

  async created() {
    this.certificateRef = db.collection("jewelry");
    const size = await db.doc("analytics/jewelry").get();
    this.tableSize = size.data().total;
    this.fetchData("start");
    this.originPath = window.location.origin;
  },

  methods: {
    clearCertCardDB() {
      axios
        .post(
          "https://us-central1-diamonds-8cf72.cloudfunctions.net/disCertificateCardPrinterApi-clearDataTable"
        )
        .then((res) => {
          console.log(res);
          return Swal.fire(res.data);
        });
    },
    onSelected(items) {
      this.selectedItemToPrint = items;
    },
    handleMultiplePrints() {
      let loader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
      });

      const lastItem = this.selectedItemToPrint.length - 1;

      this.selectedItemToPrint.forEach(async (item, index) => {
        await this.handlePrint(item, true);
        if (lastItem == index) {
          Swal.fire("Data sent", "You can now print card", "success");
          this.selectedItemToPrint = [];
          this.showSelectableTable = false;
          loader.hide();
        }
      });
    },
    closeModal() {
      this.selectedItem = {};
      this.showPreviewModal = false;
    },
    handleModal(item) {
      this.selectedItem = item;
      this.showPreviewModal = true;
    },
    upperCaseText() {
      this.filterBy.search = this.filterBy.search.toUpperCase();
    },
    evokeFilter(e) {
      if (e.keyCode == 13) {
        this.handleFilter();
      }
    },
    async handleFilter() {
      let ref = db.collection("jewelry");
      let timeFilter = false;
      if (
        !this.filterBy.search &&
        !this.filterBy.style &&
        !this.filterBy.templates &&
        !this.filterBy.companies &&
        !this.filterBy.startDate &&
        !this.filterBy.endDate
      ) {
        return false;
      }

      if (this.filterBy.startDate && this.filterBy.endDate) {
        timeFilter = true;
        let startDate = new Date(this.filterBy.startDate).getTime();
        let endDate = new Date(this.filterBy.endDate).setHours(24, 0, 0, 0);
        ref = ref.orderBy("created", "asc").startAt(startDate).endAt(endDate);
      }

      for (const key in this.filterBy) {
        if (Object.hasOwnProperty.call(this.filterBy, key)) {
          if (key != "startDate" && key != "endDate") {
            const element = this.filterBy[key];
            if (element) {
              if (key == "search") {
                ref = ref.where(this.selectedSearchOption.key, "==", element);
              }

              if (key == "templates") {
                ref = ref.where("Template.id", "==", element);
              }
              if (key == "style") {
                ref = ref.where("JewelryType", "==", element);
              }

              if (key == "companies") {
                ref = ref.where("Company.id", "==", element);
              }
            }
          }
        }
      }

      let container = timeFilter
        ? await ref.get()
        : await ref.orderBy("created", "desc").get();

      if (!container.docs.length) {
        return Swal.fire("No Data From Filter, Try Adding From Diamond Track");
      }
      this.searchedData = container.docs.map((d) =>
        Object.assign({ id: d.id }, d.data())
      );
    },
    resetTable() {
      this.pagination.currentPage = 1;
      this.fetchData("start");
    },
    async fetchData(action) {
      if (this.searchedData.length) return false;
      if (action == "start") {
        try {
          const querySnapshot = this.certificateRef
            .orderBy("created", "desc") // Change to your sorting criteria
            .endAt(0)
            .limit(this.pagination.perPage);

          this.$bind("jewelry", querySnapshot).then((docs) => {
            this.tableData = docs.filter((d) => d.CertNum);
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else if (action == "next") {
        this.fetchNextData();
      } else if (action == "prev") {
        this.fetchPreviousData();
      } else if (action == "per-page") {
        let firstDoc = this.tableData[0];
        firstDoc = await this.productsRef.doc(firstDoc.id).get();
        const querySnapshot = await this.productsRef
          .orderBy("created", "desc") // Change to your sorting criteria
          .startAt(firstDoc)
          .limit(this.pagination.perPage)
          .get();

        this.tableData = querySnapshot.docs
          .map((doc) => Object.assign({ id: doc.id }, doc.data()))
          .filter((d) => d.CertNum);
      }
    },
    async fetchNextData() {
      let lastDoc = this.tableData[this.tableData.length - 1];
      lastDoc = await this.certificateRef.doc(lastDoc.id).get();
      this.tableData = [];
      try {
        const querySnapshot = await this.certificateRef
          // .orderBy("created")
          .orderBy("created", "desc") // Change to your sorting criteria
          .startAfter(lastDoc)
          .limit(this.pagination.perPage)
          .get();

        this.tableData = querySnapshot.docs
          .map((doc) => Object.assign({ id: doc.id }, doc.data()))
          .filter((d) => d.CertNum);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async fetchPreviousData() {
      let firstDoc = this.tableData[0];
      firstDoc = await this.certificateRef.doc(firstDoc.id).get();
      this.tableData = [];
      try {
        const querySnapshot = await this.certificateRef
          // .orderBy("created")
          .orderBy("created", "asc") // Change to your sorting criteria
          .startAfter(firstDoc)
          .limit(this.pagination.perPage)
          .get();

        this.tableData = querySnapshot.docs
          .map((doc) => Object.assign({ id: doc.id }, doc.data()))
          .filter((d) => d.CertNum)
          .reverse();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    clearFilters() {
      this.searchedData = [];
      for (const key in this.filterBy) {
        if (key === "startDate" || key === "endDate") {
          this.filterBy[key] = "";
        } else {
          this.filterBy[key] = "";
        }
      }
      this.resetTable();
    },
    validateDateRange(key) {
      if (moment(this.filterBy.startDate).isAfter(this.filterBy.endDate)) {
        return Swal.fire(
          "Warning",
          "End date cannot be before start date.",
          "warning"
        ).then(() => {
          this.filterBy.startDate = null;
          this.filterBy.endDate = null;
        });
      }
      if (key === "startDate") {
        this.filterBy.endDate = this.filterBy.startDate;
      }
    },
    handleSearch(e) {
      const value = e;
      if (!value) {
        return (this.tableData = this.unfilteredItems);
      }
      this.tableData = this.unfilteredItems.filter((i) => {
        let cert = i.CertNum;
        let sku = i.ClientSKU;
        return cert.includes(value) || sku.includes(value);
      });
    },
    openLink(item) {
      this.routeChange = false;
      if (
        window.location.hostname == "admin.diamondservicesusa.com" ||
        window.location.hostname == "diamonds-8cf72.web.app"
      ) {
        if (item.CertNum) {
          window.open(
            "https://certificates.diamondservicesusa.com/jewelry/" +
              item.CertNum,
            "_blank"
          );
        } else {
          window.open(
            "https://certificates.diamondservicesusa.com/only-sku/" +
              item.ClientSKU,
            "_blank"
          );
        }
      } else {
        if (item.CertNum) {
          window.open(
            "https://digital-cert-staging-diamondservicesusa.firebaseapp.com/jewelry/" +
              item.CertNum,
            "_blank"
          );
        } else {
          window.open(
            "https://digital-cert-staging-diamondservicesusa.firebaseapp.com/only-sku/" +
              item.ClientSKU,
            "_blank"
          );
        }
      }

      setTimeout(() => {
        this.routeChange = true;
      }, 4000);
    },
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },

    exportCSV() {
      var wb = XLSX.utils.table_to_book(
        document.getElementById("jewelry-table"),
        {
          sheet: "Jewelry Sheet",
        }
      );

      for (let key in wb.Sheets["Jewelry Sheet"]) {
        if (key.includes("L") || key.includes("M")) {
          delete wb.Sheets["Jewelry Sheet"][key];
        }
      }

      var wbExport = XLSX.write(wb, {
        sheetType: "xlsx",
        bookSST: true,
        type: "binary",
      });

      var excelBlob = new Blob(
        [this.s2ab(wbExport)],
        { type: "application/vnd.ms-excel;charset=utf-8" },
        "jewelry.xlsx"
      );

      saveAs(excelBlob, "jewelry.xlsx");
    },
    exportQRCSV() {
      let columns = "Certificate,DigitalCertificateURL,VideoURL\n";
      let rows = this.tableData
        .map(
          (d) =>
            `${d.CertNum},https://diamondservicesusa.firebaseapp.com/jewelry/${d.CertNum},${d.Video.url}`
        )
        .join("\r\n");

      let csvContent = "data:text/csv;charset=utf-8," + columns + rows;
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `JewelryQR.csv`);
      document.body.appendChild(link); // Required for FF
      link.click();
    },

    importCSV(oEvent) {
      var oFile = oEvent.target.files[0];
      var sFilename = oFile.name;

      var reader = new FileReader();
      var result = {};
      let sheetNames;

      reader.onload = (e) => {
        var data = e.target.result;
        data = new Uint8Array(data);
        var workbook = XLSX.read(data, { type: "array" });
        var result = {};
        workbook.SheetNames.forEach(function (sheetName) {
          sheetNames = sheetName;
          var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
            header: 1,
          });
          if (roa.length) result[sheetName] = roa;
        });

        // see the result, caution: it works after reader event is done.
        let spredSheetHeader = result[sheetNames][0];
        result[sheetNames].splice(0, 1);
        let spredSheetData = result[sheetNames]
          .filter((dataArr) => dataArr.length > 0)
          .map((dataArr, i) => {
            let object = {};
            dataArr.forEach((data, i) => {
              if (data == "Yes") {
                data = true;
              } else if (data == "No") {
                data = false;
              }

              object[spredSheetHeader[i].toLowerCase()] = data;
            });
            return object;
          });

        this.ImportDataToDB(spredSheetData);
      };
      reader.readAsArrayBuffer(oFile);
    },

    async ImportDataToDB(spredSheetData) {
      for (let i = 0; i < spredSheetData.length; i++) {
        let ref = await db.collection("jewelry").doc();
        let data = spredSheetData[i];
        data.contact = {
          name: null,
          email: null,
          phone: null,
        };
        await batch.set(ref, data);
      }
      await batch.commit().then(() => {
        return Swal.fire(
          "Success",
          "Rows in red are imported data that needs further information",
          "success"
        );
      });
    },
    promptFileUpload() {
      document.getElementById("inputFile").click();
    },

    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }
        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
    newJewelry() {
      let ref = db.collection("jewelry").doc();
      this.handleEdit(ref);
    },
    handleEdit(item) {
      let id = item.id;
      router.push(`/jewelry/${id}`);
    },
    handlePrint(jewelry, isMultiple) {
      let loader;
      if (!isMultiple) {
        loader = this.$loading.show({
          // Optional parameters
          container: null,
          canCancel: false,
        });
      }
      const item = JSON.parse(JSON.stringify(jewelry));
      let data = Object.assign(item, {
        CertNum: item.CertNum,
        CertificateComments: item.CertificateComments,
        CardDescription: item.CardDescription,
        MainStoneShape: item.MainStoneShape,
        SideStoneShape: item.SideStoneShape,
        MainStoneWeight: item.MainStoneWeight,
        SideStoneWeight: item.SideStoneWeight,
        Type: "Jewelry",
        ClientSKU: item.ClientSKU,
        ColoredStone: item.ColoredStone || false,
        MainStoneColor: `${item.MainStoneColor.value}${item.MainStoneColor.asterisks}`,
        MainStoneColor2: `${item.MainStoneColor2.value}${item.MainStoneColor2.asterisks}`,
        SideStoneColor: `${item.SideStoneColor.value}${item.SideStoneColor.asterisks}`,
        MainStoneClarity: `${item.MainStoneClarity.value}${item.MainStoneClarity.asterisks}`,
        MainStoneClarity2: `${item.MainStoneClarity2.value}${item.MainStoneClarity2.asterisks}`,
        SideStoneClarity: `${item.SideStoneClarity.value}${item.SideStoneClarity.asterisks}`,
        MainStoneCut: item.MainStoneCut,
        SideStoneCut: item.SideStoneCut,
        Metal: item.Metal,
        Measurement: item.Measurement,
      });

      return axios
        .post(
          "https://us-central1-diamonds-8cf72.cloudfunctions.net/disCertificateCardPrinterApi-updateDataTable",
          data
        )
        .then((res) => {
          if (!isMultiple) {
            Swal.fire("Data sent", "You can now print card", "success");
            loader.hide();
          }
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.displayError(err);
          if (!isMultiple) loader.hide();
        });
    },
    handleDelete(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to undo this",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "DELETE",
        cancelButtonText: "CANCEL",
      }).then((result) => {
        if (result.value) {
          return db
            .collection("jewelry")
            .doc(item.id)
            .delete()
            .then(() => {
              Swal.fire("Deleted!", "The page has been deleted.", "success");
            });
        }
      });
    },
    goToReports(jewelry) {
      router.push(`/jewelry/${jewelry.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.search-options {
  width: 15rem;
  text-align: initial;
  margin-bottom: 5px;
}

.search-options > label {
  color: black;
}
</style>
