<template>
  <div>
    <label>{{ label }} <span v-if="required" class="required">*</span></label>
    <div class="upload_holder">
      <transition name="fade">
        <div
          v-if="!files.length && !load"
          class="upload_click drop-area"
          @click="$refs.upload.click()"
          @drop="handleDrop"
          @dragover.prevent
        >
          <md-icon>present_to_all</md-icon>
          <p>
            You currently have no media uploaded. Drag and Drop or Click here to
            upload media.
          </p>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="!files.length && load" class="loading">
          <md-icon class="rotate">autorenew</md-icon>
          <p>loading...</p>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="files.length && !load" class="image_success">
          <div class="image_wrapper" v-for="(media, i) in files" :key="i">
            <p @click="$emit('removeFile', i, media)" class="text_btn">X</p>
            <video
              controls
              class="video"
              v-if="media.type.includes('video')"
              :src="media.url"
            ></video>
            <img v-else :src="media.url" :alt="media.name" />
          </div>
        </div>
      </transition>
      <input
        ref="upload"
        multiple
        type="file"
        :accept="'image/*'"
        class="hide_upload"
        @change="uploadFiles"
      />
    </div>
  </div>
</template>

<script>
import { db, storage } from "@/config/firebaseInit";

export default {
  props: {
    label: { type: String, default: "" },
    files: { type: Array, default: [] },
    useCustomPath: { type: Boolean, default: false },
    path: { type: String, default: "" },
    required: { type: Boolean, default: false },
  },
  data() {
    return {
      load: false,
      fileURLs: [],
    };
  },
  methods: {
    handleDrop(event) {
      event.preventDefault();
      this.uploadFiles({ target: { files: event.dataTransfer.files } });
    },

    async uploadFiles(e) {
      this.upload = null;
      this.load = null;
      setTimeout(() => {
        this.load = true;
      }, 500);

      const files = Object.values(e.target.files);

      files.map(async (media, index) => {
        let reader = new FileReader();

        reader.readAsDataURL(media);
        reader.onload = async (e) => {
          let name = media.name.split(".")[0];
          if (name.includes("_")) {
            name = name.split("_")[0];
          }

          const refStr = this.useCustomPath
            ? this.path
            : `${this.path}/${name}/${Date.now()}`;
          let storageRef = storage.ref(refStr);
          let uploadTask = storageRef.put(media);

          await uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => {
              alert("could not upload images, please try again.");
            },
            (snapshot) => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                let file = {
                  url: downloadURL,
                  name: media.name,
                  type: media.type,
                  ref: refStr,
                };
                this.fileURLs.push(file);
                if (index == files.length - 1) {
                  this.load = false;
                  this.$emit("uploadedFiles", this.fileURLs);
                }
              });
            }
          );
        };
      });
    },
  },
};
</script>

<style lang="css" scoped>
.video {
  height: 300px;
}
.upload_holder {
  width: 100%;
  padding: 30px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_wrapper {
  margin-right: 10px;
  position: relative;
}
.text_btn {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  background: red;
  padding: 5px 10px;
}
.hide_upload {
  position: absolute;
  z-index: -2;
}

.upload_click,
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.upload_click .md-icon {
  font-size: 53px !important;
  opacity: 0.5;
}

.upload_click p {
  line-height: 1.3;
  max-width: 300px;
  text-align: center;
  opacity: 0.5;
  margin-top: 30px;
  margin-bottom: 0;
}

.rotate {
  font-size: 40px !important;
  -webkit-animation: loading 1s infinite linear;
}
.success {
  width: 25%;
}
.success p {
  text-align: center;
  margin-bottom: 0;
}

.success img {
  width: 100%;
  max-height: 100%;
}

.image_success {
  width: 100%;
  display: flex;
}

@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
