<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-button @click="$router.go(-1)" class="md-alert">back</md-button>
      <md-card>
        <md-card-content>
          <div class="switch-container">
            <md-switch v-model="uploadMult">Upload Multiple </md-switch>
            <div
              class="helper"
              @click="showHelper.multInfo = !showHelper.multInfo"
            >
              ?
            </div>
          </div>
          <div v-if="showHelper.multInfo" class="helper-box">
            <div v-click-outside="closeHelper"></div>
            <div class="triangle"></div>
            <p class="content">
              You can upload multiple videos, by drag and drop. The name of the
              file(s) will be used as the sku. <br />
              <span class="caution-sign"
                >MAKE SURE THE FILE'S NAME MATCHES THE PRODUCT'S SKU</span
              >
            </p>
          </div>
          <div class="static">
            <p>Company <span class="required">*</span></p>
          </div>
          <div class="md-field">
            <div class="v-select-wrapper">
              <v-select
                v-if="!uploadMult"
                required
                label="name"
                appendToBody
                :clearable="false"
                :reduce="(c) => Object.assign({ id: c.id, name: c.name })"
                :options="companies"
                v-model="digitalCertificate.client"
              >
              </v-select>
              <v-select
                v-else
                required
                label="name"
                appendToBody
                :clearable="false"
                :reduce="(c) => Object.assign({ id: c.id, name: c.name })"
                :options="companies"
                v-model="selectedClient"
              >
              </v-select>
            </div>
          </div>

          <div class="switch-container">
            <md-switch v-model="useCertificate">Use Certificate #</md-switch>
            <div
              class="helper"
              @click="showHelper.skuInfo = !showHelper.skuInfo"
            >
              ?
            </div>
          </div>
          <div v-if="showHelper.skuInfo" class="helper-box">
            <div v-click-outside="closeHelper"></div>
            <div class="triangle"></div>
            <h5>Switch from using SKU or Certificate #.</h5>
            <p class="content">
              If SKU is selected than the Digital Certificate Video will be
              created for the SKU and all the certificates using the SKU. <br />
              If Certificate # is selected than the Digital Certificate Video
              will be used only for the certificate # selected.
            </p>
          </div>

          <md-field ref="ClientSKU" v-if="!uploadMult && !useCertificate">
            <label
              >SKU (This will be the name for the video)
              <span class="required">*</span></label
            >
            <md-input
              @input="handleCertName($event)"
              :value="digitalCertificate.name"
              type="text"
            ></md-input>
            <span class="md-error">Missing Field</span>
          </md-field>
          <div v-if="!uploadMult && useCertificate">
            <md-field>
              <label
                >Certificate # (This will be the name for the video)
                <span class="required">*</span></label
              >
              <md-input
                type="text"
                @change="handleDigitalName"
                v-model="digitalCertificate.name"
              ></md-input>
              <span class="md-error">Missing Field</span>
            </md-field>
            <md-field ref="ClientSKU">
              <label>SKU IF ANY (This will be used for Datadune) </label>
              <md-input type="text" v-model="digitalCertificate.sku"></md-input>
              <span class="md-error">Missing Field</span>
            </md-field>
          </div>

          <div class="uploader" v-if="digitalCertificate.name">
            <Single
              v-if="!uploadMult"
              @uploadedFile="uploadedMedia"
              @removeFile="removeMedia"
              label="Upload Certificate Video"
              :media="digitalCertificate.meta"
              :path="`certificate_videos/${digitalCertificate.name}`"
              media_type="video"
              :id="$route.params.digital_cert_id"
              :useCustomPath="true"
              :required="true"
            />
            <div v-else>
              <div class="required">
                MAKE SURE THE FILE'S NAME MATCHES THE PRODUCT'S
                {{ useCertificate ? "CERTIFICATE #" : "SKU" }} BEFORE UPLOAD
              </div>
              <Multiple
                @uploadedFiles="handleUploadedMult"
                @removeFile="removeMultMedia"
                label="Upload Multiple Certificate Video(s)"
                :files="uploadedVideos"
                path="certificate_videos"
                media_type="video"
                :required="true"
              />
            </div>
          </div>

          <div class="uploader" v-else>
            <h3 class="required">
              MUST ADD SKU OR CERT FIRST BEFORE UPLOADING DIGITAL CERT
            </h3>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <md-button class="md-primary md-round md-small" @click="validateSave"
            >Save Digital Certificate(s)</md-button
          >
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { db, storage, functions } from "@/config/firebaseInit";
import Swal from "sweetalert2";
import Single from "@/components/Inputs/Upload.vue";
import Multiple from "@/components/Inputs/UploadMultiple.vue";

export default {
  data() {
    return {
      digitalCertificate: {
        globalSku: true,
        addedBarcode: false,
        name: "",
        client: "",
        meta: "",
        createdOn: Date.now(),
      },
      companies: [],
      upload_progress: null,
      upload_error: null,
      uploadMult: false,
      useCertificate: false,
      showHelper: {
        multInfo: false,
        skuInfo: false,
      },
      digitalCertificateExists: false,
      uploadedVideos: [],
      selectedClient: null,
      initOverrideDatadune: false,
    };
  },
  components: {
    Single,
    Multiple,
  },
  firestore() {
    return {
      companies: db.collection("companies"),
    };
  },
  created() {
    this.getDigitalCert();
  },
  methods: {
    handleCertName(e) {
      this.digitalCertificate.name = e.toUpperCase();
    },
    closeHelper() {
      if (this.showHelper.multInfo) this.showHelper.multInfo = false;
      if (this.showHelper.skuInfo) this.showHelper.skuInfo = false;
    },
    uploadedMedia(payload) {
      this.digitalCertificate.meta = payload.media;
    },
    handleUploadedMult(payload) {
      this.uploadedVideos = payload;
    },
    async getDigitalCert() {
      let digitalCertificate = await db
        .collection("digital_certificate_videos")
        .doc(this.$route.params.digital_cert_id)
        .get();
      if (digitalCertificate.exists) {
        digitalCertificate = digitalCertificate.data();
        this.digitalCertificate = digitalCertificate;
        this.digitalCertificateExists = true;
        this.initOverrideDatadune = true;
        this.digitalCertificate.globalSku
          ? (this.useCertificate = false)
          : (this.useCertificate = true);
      }
    },
    removeMedia() {
      Swal.fire({
        title: "Are you sure?",
        text: `You will not be able to undo this. Please make sure to reupload a new video or delete this entire document if no longer being used. 
        This deletion will be sent to Super Admin to be notified.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "DELETE",
        cancelButtonText: "CANCEL",
      }).then((result) => {
        if (result.value) {
          return db
            .collection("digital_certificate_videos")
            .doc(item.id)
            .delete()
            .then(() => {
              if (!this.digitalCertificate.meta.ref) {
                this.digitalCertificate.meta = {};
                return;
              }

              const imageRef = storage.ref(this.digitalCertificate.meta.ref);
              // Delete the file
              imageRef
                .delete()
                .then(() => {
                  this.digitalCertificate.meta = {};
                })
                .catch((error) => {
                  this.displayError(error);
                });
            })
            .then(() => {
              Swal.fire("Deleted!", "The file has been deleted.", "success");
            });
        }
      });
    },
    removeMultMedia(index, file) {
      const imageRef = storage.ref(file.ref);
      // Delete the file
      imageRef
        .delete()
        .then(() => {
          this.uploadedVideos.splice(index, 1);
        })
        .catch((error) => {
          this.displayError(error);
        });
    },
    displayError(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log({ errorCode, errorMessage });
      return Swal.fire(
        "Error!",
        `${errorMessage} - code: ${errorCode}. Please try again or contact support.`,
        "error"
      );
    },
    handleDigitalName(e) {
      this.digitalCertificate.name = e.target.value.replace(/\s+/g, "");
    },
    save() {
      const loader = this.$loading.show({
        container: this.$refs.loader,
        canCancel: false,
      });

      if (this.digitalCertificateExists) {
        this.digitalCertificate.updatedAt = Date.now();
      }
      this.digitalCertificate.globalSku = this.useCertificate ? false : true;
      const digital_cert_ref = db
        .collection("digital_certificate_videos")
        .doc(this.digitalCertificate.name);
      return digital_cert_ref
        .set(this.digitalCertificate, { merge: true })
        .then(() => {
          if (window.location.hostname !== "localhost")
            return this.sendDigitalCertificateTODataDune(
              this.digitalCertificate
            );
          else return false;
        })
        .then(() => {
          loader.hide();
          return Swal.fire("Success", "Video Updated", "success").then(() =>
            this.$router.go(-1)
          );
        })
        .catch((error) => {
          this.displayError(error);
        });
    },
    saveMultiple() {
      let promises = this.uploadedVideos.map((certificate) => {
        let name = certificate.name.split(".")[0];
        if (name.includes("_")) {
          name = name.split("_")[0];
        }

        let digitalCertificate = Object.assign({
          name,
          client: this.selectedClient,
          meta: certificate,
          createdOn: Date.now(),
          globalSku: this.useCertificate ? false : true,
          addedBarcode: false,
        });
        db.collection("digital_certificate_videos")
          .doc(name)
          .set(digitalCertificate, { merge: true });
        return digitalCertificate;
      });

      return Promise.allSettled(promises)
        .then((data) => {
          return data.map(
            async (certificate) =>
              await this.sendDigitalCertificateTODataDune(certificate)
          );
        })
        .then(() => {
          return Swal.fire("Success", "Video Updated", "success").then(() =>
            this.$router.go(-1)
          );
        });
    },
    validateSave() {
      let valid = true;

      if (this.uploadMult) {
        valid =
          Boolean(this.selectedClient) && Boolean(this.uploadedVideos.length);
      } else {
        for (const key in this.digitalCertificate) {
          if (
            key !== "addedBarcode" &&
            key !== "globalSku" &&
            !this.digitalCertificate[key]
          ) {
            valid = false;
          }
        }
      }

      if (!valid) {
        return Swal.fire(
          "Missing Required Fields",
          `Must fill in all fields and upload a minimum of one video`,
          "error"
        );
      } else {
        if (this.uploadMult) this.saveMultiple();
        else this.save();
      }
    },
    sendDigitalCertificateTODataDune(certificate) {
      const addJewelry = functions.httpsCallable("addJewelryToUriApi");
      const digital_cert_ref = db
        .collection("digital_certificate_videos")
        .doc(certificate.name);

      let client = certificate.client.name;
      if (client.includes("Helzberg")) {
        client = client.replace(" ", "-");
      }
      return addJewelry({
        barcode:
          this.useCertificate && !this.uploadMult
            ? certificate.sku
            : certificate.name,
        url: certificate.meta.url,
        // url: "https://firebasestorage.googleapis.com/v0/b/diamonds-8cf72.appspot.com/o/certificate_videos%2F515815?alt=media&token=680cc89e-5bc4-45a5-8a16-13cade600333",
        customer: client || "NONE",
        override: this.initOverrideDatadune ? "true" : "false",
        //override: "true",
      })
        .then((res) => {
          console.log(res);
          return digital_cert_ref.update({ addedBarcode: true });
        })
        .catch((err) => {
          console.log(err);
          return db
            .collection("rejected_datadune_certificates")
            .doc(certificate.name)
            .set(certificate);
        });
    },
  },

  watch: {},
};
</script>

<style lang="css" scoped>
.helper-box {
  position: absolute;
  width: 200px;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 99;
  margin-left: 5.5em;
  text-align: center;
}

.triangle {
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f0f0f0;
}

.content {
  margin-top: 10px;
}
.caution-sign {
  color: red;
}
.helper {
  width: 25px;
  height: 25px;
  display: block;
  text-align: center;
  padding-bottom: 15px;
  border-radius: 50px;
  border: 1px solid green;
  color: white;
  background-color: green;
  margin-top: 12px;
  cursor: pointer;
}

.switch-container {
  display: flex;
  flex-direction: row;
}
.v-select-wrapper {
  width: 100%;
}
.uploader {
  min-width: 500px;
  max-width: 800px;
}
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
