<template>
  <md-card class="light">
    <md-card-content>
      <!-- <div class="btn-row stats_holder">
        <div class="filters">
         

          <md-button
            style="margin-top: 52px"
            class="md-success"
            @click="handleFilter"
            >Filter</md-button
          >
        </div>
      </div> -->

      <md-card class="light">
        <md-card-content>
          <div class="search-options">
            <label for="">Search For: </label>
            <v-select
              :options="searchOptions"
              v-model="selectedSearchOption"
              label="name"
              :clearable="false"
            >
            </v-select>
          </div>
          <md-field class="search-bar">
            <md-input
              type="search"
              clearable
              :placeholder="`Search records by ${selectedSearchOption.name}`"
              v-model="filterBy.search"
              @input="upperCaseText"
              @keypress="evokeFilter($event)"
            ></md-input>
          </md-field>
          <div class="filters">
            <div class="filter">
              <p>Filter By Month</p>
              <v-select
                :appendToBody="true"
                v-model="filterBy.selectedMonth"
                :options="selectableMonths"
                :clearable="false"
                label="name"
              >
              </v-select>
            </div>
            <!-- <div class="filter">
              <p>Start From:</p>
              <md-datepicker
                class="datepicker"
                @input="validateDateRange('startDate')"
                v-model="filterBy.startDate"
              />
            </div>
            <div class="filter">
              <p>End To:</p>
              <md-datepicker
                class="datepicker"
                @input="validateDateRange('endDate')"
                v-model="filterBy.endDate"
              />
            </div> -->

            <div
              class="flex flex-row justify-content-between"
              style="width: 300px; bottom: 5px; position: absolute; right: 0"
            >
              <md-button
                style="background-color: green !important"
                class="text-btn"
                @click="handleFilter"
                >Filters</md-button
              >
              <md-button
                style="background-color: red !important"
                class="text-btn"
                @click="clearFilters"
                >Clear Filters</md-button
              >
            </div>
          </div>
        </md-card-content>
      </md-card>
      <md-table
        md-fixed-header
        :value="queriedData"
        :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder"
        :md-sort-fn="customSort"
        class="paginated-table table-striped table-hover"
        id="jewelry-table"
      >
        <md-table-toolbar :style="{ marginBottom: '20px' }">
          <md-field>
            <label for="jewelry">Per page</label>
            <md-select v-model="pagination.perPage" name="jewelry">
              <md-option
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
                >{{ item }}</md-option
              >
            </md-select>
          </md-field>
        </md-table-toolbar>

        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Month">
            {{ item.month }}
          </md-table-cell>
          <md-table-cell md-label="Product">{{ item.name }}</md-table-cell>
          <md-table-cell md-label="Views">{{
            item.total_certificate_views
          }}</md-table-cell>
          <md-table-cell md-label="Ad Clicks">{{
            item.total_clicks_to_company_site
          }}</md-table-cell>
          <md-table-cell md-label="Per Devices">
            <div class="devices">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Views</th>
                    <th>AD Clicks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="device"
                    v-for="(device, name) in item.devices"
                    :key="name"
                  >
                    <td>{{ name }}</td>
                    <td>{{ device.certificate_views }}</td>
                    <td>{{ device.clicks_to_company_site }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </md-table-cell>
          <!-- <md-table-cell md-label="Controls">
            <md-button
              @click="openLink(item)"
              class="md-just-icon md-warning md-simple"
            >
              <md-icon>link</md-icon>
              <md-tooltip>View Digital Certificate</md-tooltip>
            </md-button>

            <md-button
              class="md-just-icon md-warning md-simple"
              @click.native="handleModal(item)"
            >
              <md-icon class="md-success">visibility</md-icon>
              <md-tooltip md-direction="bottom"
                >Quick Preview</md-tooltip
              > </md-button
            ><md-button
              class="md-just-icon md-warning md-simple"
              @click.native="handleEdit(item)"
            >
              <md-icon>edit</md-icon>
              <md-tooltip>Go To Detail Page</md-tooltip>
            </md-button>
            <md-button
              class="md-just-icon md-warning md-simple"
              @click.native="handlePrint(item)"
            >
              <md-icon>print</md-icon>
              <md-tooltip>Print Card Certificate</md-tooltip>
            </md-button>
          </md-table-cell> -->
        </md-table-row>
      </md-table>
    </md-card-content>
    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
      </div>
      <Pagination
        class="pagination-no-border pagination-success"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
        :allowClick="Boolean(tableData.length + searchedData.length)"
      ></Pagination>
    </md-card-actions>
  </md-card>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import { db } from "@/config/firebaseInit";
import { DateTime } from "luxon";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "analytics-table",
  components: {
    Pagination,
  },
  props: {
    selectableMonths: {
      type: Array,
      default: [],
    },
    analytics: {
      type: Array,
      default: [],
    },
    tableData: {
      type: Object,
      default: {},
    },

    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = [];
      if (this.searchedData.length > 0) {
        result = this.searchedData.slice(this.from, this.to);
        return result;
      }

      if (this.filterBy.selectedMonth !== "ALL") {
        // console.log(
        //   this.tableData[this.filterBy.selectedMonth],
        //   this.tableData
        // );
        let certificates =
          this.tableData[this.filterBy.selectedMonth].certificates;
        for (const subkey in certificates) {
          if (
            Object.hasOwnProperty.call(
              this.tableData[this.filterBy.selectedMonth].certificates,
              subkey
            )
          ) {
            const certificate =
              this.tableData[this.filterBy.selectedMonth].certificates[subkey];

            if (certificate.created_at) {
              certificate.month = this.filterBy.selectedMonth;
              certificate.name = subkey;
              result.push(certificate);
            }
          }
        }
      } else {
        for (const key in this.tableData) {
          if (Object.hasOwnProperty.call(this.tableData, key)) {
            const element = this.tableData[key];
            // console.log(element);
            for (const subkey in element.certificates) {
              if (Object.hasOwnProperty.call(element.certificates, subkey)) {
                const certificate = element.certificates[subkey];

                if (certificate.created_at) {
                  certificate.month = key;
                  certificate.name = subkey;
                  result.push(certificate);
                }
              }
            }
          }
        }
      }

      this.tableSize = result.length;

      return this.sortMonthsArray(result).reverse();
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableSize;
    },
  },
  data() {
    return {
      currentSort: "name",
      currentSortOrder: "asc",
      searchedData: [],
      tableSize: 0,
      selectedSearchOption: {
        name: "Client SKU",
        key: "ClientSKU",
      },
      searchOptions: [
        {
          name: "Client SKU",
          key: "ClientSKU",
        },
        {
          name: "Cert #",
          key: "CertNum",
        },
      ],
      filterBy: {
        selectedMonth: "ALL",
        startDate: null,
        endDate: null,
        search: "",
      },
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 250, 500],
        total: 0,
      },
    };
  },
  methods: {
    sortMonthsArray(monthsArray) {
      const monthOrder = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      // Create a map of month names to their index positions
      const monthIndex = monthOrder.reduce((acc, month, index) => {
        acc[month] = index;
        return acc;
      }, {});

      // Sort the monthsArray based on the monthIndex mapping
      return monthsArray.sort((a, b) => monthIndex[a.id] - monthIndex[b.id]);
    },

    upperCaseText() {
      this.filterBy.search = this.filterBy.search.toUpperCase();
    },
    clearFilters() {
      this.filterBy.search = "";
      this.searchedData = [];
    },
    async handleFilter() {
      let value = this.filterBy.search;
      let cert;
      let filteredMonth;
      if (this.selectedSearchOption.key == "CertNum") {
        cert = await db.doc(`jewelry/${value}`).get();
        if (cert.exists) {
          cert = cert.data();
          value = cert.ClientSKU || cert.CertNum;
          const dateTime = DateTime.fromMillis(cert.created);
          filteredMonth = dateTime.toFormat("MMMM");
        }
      }
      this.analytics.forEach((a) => {
        if (cert && filteredMonth) {
          if (a.id == filteredMonth) {
            this.searchedData.push(a.certificates[value]);
          }
          return;
        } else {
          if (a.certificates[value]) {
            this.searchedData.push(a.certificates[value]);
          }
        }
      });
    },
    validateDateRange(key) {
      if (moment(this.filterBy.startDate).isAfter(this.filterBy.endDate)) {
        return Swal.fire(
          "Warning",
          "End date cannot be before start date.",
          "warning"
        ).then(() => {
          this.filterBy.startDate = null;
          this.filterBy.endDate = null;
        });
      }
      if (key === "startDate") {
        this.filterBy.endDate = this.filterBy.startDate;
      }
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }
        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
  },
};
</script>
